import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Profile, currencyCharts } from './profile';
import { RegurarlyInvestment } from './regurarlyInvestment';
import { SingleInvestment } from './singleInvestment';
import { periodeEnum } from '../profile/periodeEnum';
import { Observable, Subject } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { NgModule } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Sale } from './sale';
import { DataInvestment } from '../data-investment/dataInvestment';
import { GraphInvestmentComponent } from '../graph-investment/graph-investment.component';
import { DataInvestmentComponent } from '../data-investment/data-investment.component';
import { UserIdleService } from 'angular-user-idle';
import { FormControl } from '@angular/forms';
import { PdfmakeService } from 'ng-pdf-make/pdfmake/pdfmake.service';
import { environment } from '../../environments/environment';
import { PdfService } from '../pdf/pdf.services';
import { CheckColumns } from './checkColumns';
import { CheckExport } from './checkExport';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isNullOrUndefined, isNumber } from 'util';
import { RouterModule, Routes, Route } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { finalize, tap, window } from 'rxjs/operators';

import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask/src/currency-mask.config';
export let CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'center',
  allowNegative: true,
  decimal: ',',
  precision: 0,
  prefix: '',
  suffix: '  Kč  ',
  thousands: ' ',
};




import { debounceTime, distinctUntilChanged, delay, flatMap } from 'rxjs/operators';
//import { state } from '@angular/animations';

const subject = new Subject<string>();



const maxCountCalculateRent = 1000;



@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  animations: [
    trigger('flyInOutPanel', [
      state('open', style({
        transform: 'translateX(0px)'
      })),
      state('close', style({
        transform: 'translateY(2500px)', display: 'none'
      })),
      transition('smaller <=> larger', animate(8000))
    ])
  ]
})
export class ProfileComponent implements OnInit {
  @ViewChild(DataInvestmentComponent) dataInvestmentComponent: DataInvestmentComponent;
  @ViewChild(GraphInvestmentComponent) graphComponent: GraphInvestmentComponent;
  dataInvestment: DataInvestment[] = [];
  dataRowSingle: Object[] = [];
  pdfContent: Object[] = [];
  profileInvestment: Profile;
  activeSingleInvestment: number;
  activeRegurarlyInvestment: number;
  statusMakePdf: boolean;
  logoBase64: string;
  dataHorizontGraph: Array<any> = [];
  enabledGraph: boolean;
  checkedDetail: boolean;
  saleTable: Array<Sale> = [];
  checkColumns: CheckColumns;
  isOpen = false;
  urlWiki = environment.urlWiki;
  urlCore = environment.urlCore;
  urlAnalyzer = environment.urlAnalyzer;
  logo = environment.urlLogo;


  //const subject = new Subject<string>();

  myControl: FormControl = new FormControl();
  public keyUp = new Subject<string>();
  chargeModeSingleControl: FormControl = new FormControl();
  chargeModeRegurarlyControl: FormControl = new FormControl();
  public version: string = environment.VERSION;
  reload: boolean;
  enumChargeModeEnum = [
    'Průběžný poplatek vypočtený z brutto platby ',
    'Průběžný poplatek vypočtený z netto investované částky'
  ];
  constructor(private pdfmake: PdfmakeService, private pdfservice: PdfService, public dialog: MatDialog, public authService: AuthService, private http: HttpClient
    , @Inject(CURRENCY_MASK_CONFIG) private currencyFormatInfo: CurrencyMaskConfig, private userIdle: UserIdleService) {



    this.checkColumns = new CheckColumns();


    const subscription = this.keyUp
      .pipe(
        debounceTime(1000)
      )
      .pipe(distinctUntilChanged())
      .pipe(delay(500))
      .subscribe(() => { this.recalcInvestmentAll(); });
  }

  toogleLeftPanel() {
    console.log(this.isOpen);
    if (!this.isOpen) {
    document.body.style.overflow = 'hidden';
    } else{
      document.body.style.overflow = 'auto';
    }
    this.isOpen = !this.isOpen;
  }



  ngOnInit() {
    //Start watching for user inactivity.
    this.userIdle.startWatching();
    // Start watch when time is up.
    this.userIdle.onTimerStart().subscribe(() => {
      this.authService.logout();
    });
    this.profileInvestment = new Profile();
    this.checkedDetail = false;
    this.iniImageLogo();
    this.iniImageHeader();
  }


  iniImageLogo() {
    this.http.get(environment.logoPdf, { responseType: 'blob' })
      .subscribe(res => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result;
          this.pdfservice.loadLogoToPdf( base64data.toString());
        }
        reader.readAsDataURL(res);
      //  console.log(res);
      });
  }

  iniImageHeader() {
    this.http.get(environment.headerBackgroundPdf, { responseType: 'blob' })
      .subscribe(res => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64dataHeader = reader.result;
          this.pdfservice.loalHeaderToPdf( base64dataHeader.toString());
        }
        reader.readAsDataURL(res);
      });

  }

  // tslint:disable-next-line:member-ordering
  getEnumString(enuma: any, key: any): String {
    return this.enumChargeModeEnum[key];
  }

  setValidSingleInvestment(index: any, valid: boolean) {
    this.profileInvestment.singleInvestments[index].valid = valid;
  }

  setValidRegurarlyInvestment(index: any, valid: boolean) {
    this.profileInvestment.regurarlyInvestments[index].valid = valid;
  }







  /*
    currencyMaskFocus($event): void
    {
      //getting current value
      const value =  $event.currentTarget.value;
      console.log('focus' + value);
      //splitting by the value by the decimal separator
      const splitted = value.split(this.currencyFormatInfo.decimal);
      //selecting the integer part, which will be replaced with what the user will type
      //For example: 100,82 - where , is the decimal separator
      //We would select 100
      $event.currentTarget.selectionStart = 0;
      $event.currentTarget.selectionEnd = splitted[0].length;
    }



    parser($event): any
    {
      return $event.substring(0, $event.indexOf('%') - 1))

    }

    currencyMastKeydown($event): void
    {


      //When the user presses ,
      console.log($event.currentTarget.value);
      //We want to select the decimal part to allow editting only the decimal part
      if (($event.key === ',') && !($event.currentTarget.value.indexOf(',') > 0) &&
       Number(parseFloat($event.currentTarget.value.charAt(0))) ) {
        $event.currentTarget.value = ($event.currentTarget.value.substring(0, $event.currentTarget.value.indexOf(' '))) + ','
        + ' % ';
        //console.log($event.currentTarget.value.substring(0, $event.currentTarget.value.indexOf(' ')));
        //First of all, we cancel all event propagation and the default behavior!
        $event.stopPropagation();
        $event.preventDefault();
        //Then we get the value itself
        const value =  $event.currentTarget.value;
        //Find out where the decimal separator ist
        const start = value.indexOf(this.currencyFormatInfo.decimal) + 1;
        //select only the decimal part
        //For example: 100,82 - where , is the decimal separator
        //Would select only 82
        $event.currentTarget.selectionStart=start;
        $event.currentTarget.selectionEnd=start+this.currencyFormatInfo.precision;
      } else if ( Number(parseFloat($event.key)) ||(  $event.key === "Backspace")) {
        console.log('ne strednik');
        console.log('pred' + $event.currentTarget.value);
        if (isNumber($event.key) ){
        $event.currentTarget.value = ($event.currentTarget.value.substring(0, $event.currentTarget.value.indexOf('%') - 1)) + $event.key;
        //$event.stopPropagation();
        //$event.preventDefault();
        console.log('po' + $event.currentTarget.value);
        }
      } else{
        $event.stopPropagation();
        $event.preventDefault();
      }
      if(!$event.currentTarget.value.indexOf('%') ){
        $event.currentTarget.value = ($event.currentTarget.value + ' % ';
      }


    }
    */

  setSwicthChart() {
    if (!this.profileInvestment.rent.on) {
      this.graphComponent.show_chart = 1;
    }
  }

  calculateRent() {
    switch (this.profileInvestment.rent.typeCalculation) {
      case 1:
        this.calculateHorizonRent();
        this.ifFullLastRentNominal();
        return;
      case 2:
        this.calculateHorizonRent();
        this.ifFullLastRentReal();
        return;
      case 3:
        this.calculatePaymentRent(this.profileInvestment.rent.rentCalculateHorizon);
        return;
      case 4:
        this.calculatePaymentRentReal(this.profileInvestment.rent.rentCalculateHorizon);
        return;
    }
  }

  ifFullLastRentReal() {
    if (this.dataInvestment[this.dataInvestment.length - 1].cashoutReal === this.dataInvestment[this.dataInvestment.length - 2].cashoutReal) {
      this.profileInvestment.rent.lastFullRent = true;
    } else {
      this.profileInvestment.rent.lastFullRent = false;
    }
  }

  ifFullLastRentNominal() {

    if (this.dataInvestment[this.dataInvestment.length - 1].cashout === this.dataInvestment[this.dataInvestment.length - 2].cashout) {
      this.profileInvestment.rent.lastFullRent = true;
    } else {
      this.profileInvestment.rent.lastFullRent = false;
    }
  }

  calculateInvestmentEvalution(amount: number, bruttoEvalution: number, month: number, periode: number) {
    const total = amount * Math.pow(1 + (bruttoEvalution / 100 / periode), month * periode / 12);
    return total;
  }

  sumInvestment() {
    for (let index_horizon = 0; index_horizon < this.dataInvestment.length; index_horizon++) {
      this.dataInvestment[index_horizon].totalInvestment = this.dataInvestment[index_horizon].singleInvestment + this.dataInvestment[index_horizon].raegurarlyInvestment;
      this.dataInvestment[index_horizon].totalInvestmentWithInflation = this.dataInvestment[index_horizon].singleInvestmentWithInflation + this.dataInvestment[index_horizon].regurarlyInvestmentWithInflation;
    }
  }

  calcInvestmentAll() {
    this.setHorizonTableInvestment();
    this.mergeInvestmentRegurarly();
    this.mergeInvestmentSingle();
    this.sumInvestment();
    if (this.profileInvestment.rent.on) {
      this.calculateHorizonRent();
    }
  }

  /*
    * @requires $index - index pravidelné platby, která má být přepočítána
    * @function
    *
    * @description
    * Vypočte zhodnocení pro jednotlivé měsíce
    *
    */
  recalcInvestmentRegurarlyTotal(investmentRegurarly: RegurarlyInvestment) {
    // aktualní index zhodnocovaného vkladu
    let actual_index_investment = 0;
    investmentRegurarly.setSumCharge();
    //const length_investment = this.profileInvestment.regurarlyInvestments[index_investment].dataInvestment.length;
    for (let dataInvestment of investmentRegurarly.dataInvestment) {
      if (dataInvestment.deposit > 0) {
        for (let mounth = 0; investmentRegurarly.dataInvestment.length > actual_index_investment + mounth; ++mounth) {
          let total = this.calculateInvestmentEvalution(dataInvestment.deposit - dataInvestment.charge, this.profileInvestment.assumptionEvaluation, mounth + 1, investmentRegurarly.periode);
          if (!isNaN(total)) {
            investmentRegurarly.dataInvestment[actual_index_investment + mounth].totalInvestment += total;
          }
          //let totalInflacion = this.calculateInvestmentEvalution(dataInvestment.deposit - dataInvestment.charge, this.profileInvestment.evaluationWithInflation, mounth + 1, investmentRegurarly.periode);

          //let totalInflacion = total  * Math.pow(( 1 + (( 0 - this.profileInvestment.assumptionInflation / 100 ) / (1 + this.profileInvestment.assumptionInflation / 100 ))), ( mounth + 1 ) / 12 ) ;

          // let totalInflacion = total  * Math.pow(( 1 + (( 0 - this.profileInvestment.assumptionInflation / 100 ) / (1 + this.profileInvestment.assumptionInflation / 100 ))),  ( mounth + 1 ) / 12 ) ;
          // totalInflacion  += dataInvestment.charge;
          // let totalInflacion = this.calculateInvestmentEvalution(total , -1 * this.profileInvestment.assumptionInflation, (mounth + 1), investmentRegurarly.periode);

          //let totalInflacion = this.calculateInvestments(total, 0, this.profileInvestment.assumptionInflation, (mounth + 1) % 12, mounth + 1, 12);
          /* if (!isNaN(total)) {
             //investmentRegurarly.dataInvestment[actual_index_investment + mounth].totalInvestmentInflation = investmentRegurarly.dataInvestment[actual_index_investment + mounth].totalInvestment * Math.pow(( 1 + (( 0 - this.profileInvestment.assumptionInflation / 100 ) / (1 + this.profileInvestment.assumptionInflation / 100 ))),  mounth + 1 / 12 ) ;
             investmentRegurarly.dataInvestment[actual_index_investment + mounth].totalInvestmentInflation += totalInflacion;
           }*/
        }
      }
      actual_index_investment++;
    }

    return investmentRegurarly;
  }



  recalcInvestmentSingleTotal(investmentSingle: SingleInvestment) {
    // aktualní index zhodnocovaného vkladu
    let actual_index_investment = 0;
    //const length_investment = this.profileInvestment.regurarlyInvestments[index_investment].dataInvestment.length;
    for (let dataInvestment of investmentSingle.dataInvestment) {
      if (dataInvestment.deposit > 0) {
        for (let mounth = 0; investmentSingle.dataInvestment.length > actual_index_investment + mounth; ++mounth) {
          let total = this.calculateInvestmentEvalution(dataInvestment.deposit - dataInvestment.charge, this.profileInvestment.assumptionEvaluation, mounth + 1, 12);
          if (!isNaN(total)) {
            investmentSingle.dataInvestment[actual_index_investment + mounth].totalInvestment += total;
          }
        }
        break;
      }
      actual_index_investment++;
    }
    return investmentSingle;
  }


  recalcInvestment() {
    this.setHorizonTableInvestment();
    this.mergeInvestmentRegurarly();
    this.mergeInvestmentSingle();
    this.sumInvestment();
  }


  recalcInvestmentAll() {
    this.profileInvestment.setEvaluationWithInflation();
    if (this.profileInvestment.isValidInvestmentsAll() && this.profileInvestment.valid && (this.profileInvestment.horizon < 481)) {
      for (let index = 0; index < this.profileInvestment.regurarlyInvestments.length; index++) {
        this.recalcRegurarlyInvestment(index);
      }
      for (let index = 0; index < this.profileInvestment.singleInvestments.length; index++) {
        this.recalcSingleInvestment(index);
      }
      this.calcInvestmentAll();
      this.calculateRent();
      this.filldata();
    } else {
      return;
    }
    //this.graphComponent.updateChart();
  }


  checkValidationHorzon() {
    let remove_data = false;
    for (let index = 0; index < this.profileInvestment.regurarlyInvestments.length; index++) {
      if (this.profileInvestment.horizon < (this.profileInvestment.regurarlyInvestments[index].horizon + this.profileInvestment.regurarlyInvestments[index].horizonStart - 1)) {
        this.profileInvestment.regurarlyInvestments[index].valid = false;
        remove_data = true;
      } else {
        this.profileInvestment.regurarlyInvestments[index].valid = true;
      }
    }

    for (let index = 0; index < this.profileInvestment.singleInvestments.length; index++) {
      if (this.profileInvestment.horizon < this.profileInvestment.singleInvestments[index].number_charge) {
        this.profileInvestment.singleInvestments[index].valid = false;
        remove_data = true;
      } else {
        this.profileInvestment.singleInvestments[index].valid = true;
      }
    }

    if (remove_data) {
      this.dataInvestment.splice(0, this.dataInvestment.length);
    }
  }


  recalcRegurarlyInvestment(index: number) {
    if (isNullOrUndefined(this.profileInvestment.regurarlyInvestments[index].horizonStart)) {
      return;
    }

    //this.profileInvestment.regurarlyInvestments[index].selectModeRegurarlyCharge();
    const regurarlyInvestment = this.recalcInvestmentRegurarlyTotal(this.calculateDepositRegurarly(this.profileInvestment.regurarlyInvestments[index]));
    this.profileInvestment.regurarlyInvestments[index] = regurarlyInvestment;
  }


  setEvaluationWithInflation() {
    // tslint:disable-next-line:max-line-length
    this.profileInvestment.evaluationWithInflation = (((this.profileInvestment.assumptionEvaluation / 100) - (this.profileInvestment.assumptionInflation / 100)) / (1 + (this.profileInvestment.assumptionInflation / 100))) * 100;
  }


  recalcSingleInvestment(index: number) {
    const singleInvestment = this.recalculateDepositSingle(this.profileInvestment.singleInvestments[index]);
    this.profileInvestment.singleInvestments[index] = singleInvestment;
    //this.filldata();
  }




  setHorizonTableInvestment() {
    this.dataInvestment.splice(0, this.dataInvestment.length);
    for (let count = 0; count < this.profileInvestment.horizon; count++) {
      let data = new DataInvestment();
      this.dataInvestment.push(data);
    }
  }


  mergeInvestmentRegurarly() {
    for (let investmentRegurarly of this.profileInvestment.regurarlyInvestments) {
      let payment_sum = 0;
      for (let index_horizon = 0; index_horizon < investmentRegurarly.dataInvestment.length; index_horizon++) {
        if (isNullOrUndefined(investmentRegurarly.dataInvestment[index_horizon])) {
          console.log('pravidelna investice nema data' + index_horizon);
        }
        if (isNullOrUndefined(investmentRegurarly.dataInvestment[index_horizon].deposit) || isNaN(investmentRegurarly.dataInvestment[index_horizon].totalInvestment)) {
          this.dataInvestment[index_horizon].payment += 0;
          this.dataInvestment[index_horizon].raegurarlyInvestment += 0;
        } else {
          this.dataInvestment[index_horizon].charge += investmentRegurarly.dataInvestment[index_horizon].charge;
          this.dataInvestment[index_horizon].raegurarlyInvestment += investmentRegurarly.dataInvestment[index_horizon].totalInvestment;
          //this.dataInvestment[index_horizon].regurarlyInvestmentWithInflation += investmentRegurarly.dataInvestment[index_horizon].totalInvestmentInflation;

          this.dataInvestment[index_horizon].regurarlyInvestmentWithInflation += investmentRegurarly.dataInvestment[index_horizon].totalInvestment * Math.pow((1 + ((0 - this.profileInvestment.assumptionInflation / 100) / (1 + this.profileInvestment.assumptionInflation / 100))), (index_horizon + 1) / 12);

          if (index_horizon !== 0) {
            payment_sum += investmentRegurarly.dataInvestment[index_horizon].deposit;
            this.dataInvestment[index_horizon].payment += payment_sum;
            this.dataInvestment[index_horizon].chargeCumulatively += this.dataInvestment[index_horizon - 1].chargeCumulatively + investmentRegurarly.dataInvestment[index_horizon].charge;
            this.dataInvestment[index_horizon].payementInflation += this.calculateInflation(payment_sum, this.profileInvestment.assumptionInflation, index_horizon + 1);
          } else {
            payment_sum += investmentRegurarly.dataInvestment[index_horizon].deposit;
            this.dataInvestment[index_horizon].payment += investmentRegurarly.dataInvestment[index_horizon].deposit;
            this.dataInvestment[index_horizon].payementInflation += this.calculateInflation(payment_sum, this.profileInvestment.assumptionInflation, index_horizon + 1);
            this.dataInvestment[index_horizon].chargeCumulatively += investmentRegurarly.dataInvestment[index_horizon].charge;
          }
        }
      }
    }
  }

  mergeInvestmentSingle() {
    let index_horizon = 0;
    for (let investmentSingle of this.profileInvestment.singleInvestments) {
      investmentSingle.dataInvestment.slice(0, this.profileInvestment.horizon);
      let payment_sum = 0;

      for (index_horizon = 0; index_horizon < this.profileInvestment.horizon; index_horizon++) {
        if (isNullOrUndefined(investmentSingle.dataInvestment[index_horizon])) {
          break;
        }
        if (isNullOrUndefined(this.dataInvestment[index_horizon].payment)) {
          this.dataInvestment[index_horizon].payment = 0;
        }
        if (isNaN(investmentSingle.dataInvestment[index_horizon].deposit) || isNaN(investmentSingle.dataInvestment[index_horizon].totalInvestment)) {
          this.dataInvestment[index_horizon].payment += 0;
          this.dataInvestment[index_horizon].singleInvestment += 0;
        }
        else if ((investmentSingle.number_charge - 1) <= index_horizon) {
          payment_sum += investmentSingle.dataInvestment[index_horizon].deposit;
          this.dataInvestment[index_horizon].payment += payment_sum;
          this.dataInvestment[index_horizon].charge += investmentSingle.dataInvestment[index_horizon].charge;
          this.dataInvestment[index_horizon].singleInvestment += investmentSingle.dataInvestment[index_horizon].totalInvestment;
          this.dataInvestment[index_horizon].singleInvestmentWithInflation += investmentSingle.dataInvestment[index_horizon].totalInvestmentInflation;
          this.dataInvestment[index_horizon].payementInflation += this.calculateInflation(payment_sum, this.profileInvestment.assumptionInflation, index_horizon + 1);
          this.dataInvestment[index_horizon].chargeCumulatively += investmentSingle.charge;
        }
      }
    }
  }


  /*
      * @requires $regurarlyInvestment -
      * @function
      *
      * @description
      * Výpočete součet všechn jednorázových plateb k danému měsíci  $number_charge
      *
      */
  recalculateDepositSingle(investmentSingle: SingleInvestment): SingleInvestment {
    investmentSingle.setHorizon(this.profileInvestment.horizon);
    investmentSingle.setDeposit();
    investmentSingle.setChargeSingle();
    return this.setInvestmentSinglTotal(investmentSingle, this.profileInvestment.assumptionEvaluation, this.profileInvestment.assumptionInflation);
  }

  /*
      * @requires $regurarlyInvestment -
      * @function
      *
      * @description
      * Výpočete součet všechn jednorázových plateb k danému měsíci  $number_charge
      *
      */
  setInvestmentSinglTotal(investmentSingle: SingleInvestment, evaluation: number, inflation: number) {
    let actual_index_investment = 0;
    let evolutionStart = false;
    for (let dataInvestment of investmentSingle.dataInvestment) {
      if ((investmentSingle.number_charge - 1) === actual_index_investment) {
        dataInvestment.totalInvestment = this.calculateInvestmentEvalution(investmentSingle.singleBruttoInvestment - investmentSingle.charge, evaluation, 1, 1);
        dataInvestment.totalInvestmentInflation = this.calculateInflation(dataInvestment.totalInvestment, this.profileInvestment.assumptionInflation, actual_index_investment + 1);
        // dataInvestment.totalInvestmentInflation += this.calculateInflation(dataInvestment.totalInvestment, this.profileInvestment.assumptionInflation, actual_index_investment + 1);
        evolutionStart = true;
      } else if (evolutionStart) {
        dataInvestment.totalInvestment += this.calculateInvestmentEvalution(investmentSingle.singleBruttoInvestment - investmentSingle.charge, evaluation, actual_index_investment - investmentSingle.number_charge + 2, 1);
        dataInvestment.totalInvestmentInflation += this.calculateInflation(dataInvestment.totalInvestment, this.profileInvestment.assumptionInflation, actual_index_investment + 1);

      }
      actual_index_investment++;
    }
    return investmentSingle;
  }





  /*
    * @requires $regurarlyInvestment -
    * @function
    *
    * @description
    * Výpočete součet všechn jednorázových plateb k danému měsíci  $number_charge
    *
    */
  calculateDepositRegurarly(investmentRegurarly: RegurarlyInvestment): RegurarlyInvestment {
    let rate_percent = 0;
    let deposit = 0;
    let charge = 0;


    // typ kalkulace 'Konstantní'
    switch (investmentRegurarly.typeChargeRegurarly) {
      case 1:
        investmentRegurarly.setHorizon(this.profileInvestment.horizon);
        for (let i = 0; i < (investmentRegurarly.horizon + investmentRegurarly.horizonStart - 1); i++) {
          if (isNullOrUndefined(investmentRegurarly.dataInvestment[i])) {
            break;
          }
          // výpočet vkladu
          deposit = investmentRegurarly.calculateDepsit(i - investmentRegurarly.horizonStart + 2);
          if (((investmentRegurarly.horizon + investmentRegurarly.horizonStart - 1) > i) && ((investmentRegurarly.horizonStart - 1) <= i)) {
            if (deposit !== 0) {
              investmentRegurarly.dataInvestment[i].charge = this.calculateCharge(deposit, investmentRegurarly.chargeFromInvestment, investmentRegurarly.calculationRegurarlyCharge);
              investmentRegurarly.charge = investmentRegurarly.dataInvestment[i].charge;
            }
            investmentRegurarly.dataInvestment[i].deposit = deposit;
          }
        }
        investmentRegurarly.setSumCharge();
        investmentRegurarly.setSumChargeInflation(this.calculateInflation(investmentRegurarly.sumChargeRegurarly, this.profileInvestment.assumptionInflation, (investmentRegurarly.horizonStart - 1) + investmentRegurarly.horizon));
        break;
      // // typ kalkulace 'Se slevou na objem'
      case 2:
        investmentRegurarly.setHorizon(this.profileInvestment.horizon);
        for (let i = 0; i < (investmentRegurarly.horizon + investmentRegurarly.horizonStart - 1); i++) {
          if (isNullOrUndefined(investmentRegurarly.dataInvestment[i])) {
            break;
          }
          // výpočet vkladu
          deposit = investmentRegurarly.calculateDepsit(i - investmentRegurarly.horizonStart + 2);
          if (((investmentRegurarly.horizon + investmentRegurarly.horizonStart) > i) && ((investmentRegurarly.horizonStart - 1) <= i)) {
            let sum_deposit = investmentRegurarly.sumDeposit(i);
            if (investmentRegurarly.calculateSaleSingleInvestment) {
              sum_deposit = sum_deposit + this.sumInvestmentSingle(i);
            }
            //Zjišťuje procento polatku s případnou slevu za objem
            rate_percent = investmentRegurarly.calculateSale(sum_deposit);
            if (deposit !== 0) {
              charge = this.calculateCharge(deposit, rate_percent, investmentRegurarly.calculationRegurarlyCharge);
              investmentRegurarly.dataInvestment[i].charge = charge;
            }
            investmentRegurarly.dataInvestment[i].deposit = deposit;
          }
        }
        investmentRegurarly.setSumCharge();
        investmentRegurarly.setSumChargeInflation(this.calculateInflation(investmentRegurarly.sumChargeRegurarly, this.profileInvestment.assumptionInflation, (investmentRegurarly.horizonStart - 1) + investmentRegurarly.horizon));
        break;
      // typ kalkulace  'Placený zvlášť s prvním vkladem'
      case 3:
        investmentRegurarly.setHorizon(this.profileInvestment.horizon);
        investmentRegurarly.setSumCharge();
        investmentRegurarly.setSumChargeInflation(this.calculateInflation(investmentRegurarly.sumChargeRegurarly, this.profileInvestment.assumptionInflation, (investmentRegurarly.horizonStart - 1) + investmentRegurarly.horizon));
        for (let i = 0; i < (investmentRegurarly.horizon + investmentRegurarly.horizonStart - 1); i++) {
          if (isNullOrUndefined(investmentRegurarly.dataInvestment[i])) {
            break;
          }
          // výpočet vkladu
          deposit = investmentRegurarly.calculateDepsit(i - investmentRegurarly.horizonStart + 2);
          if (((investmentRegurarly.horizon + investmentRegurarly.horizonStart) > i) && ((investmentRegurarly.horizonStart - 1) <= i)) {
            if (deposit !== 0) {
              if (i === (investmentRegurarly.horizonStart - 1)) {
                investmentRegurarly.dataInvestment[i].charge = investmentRegurarly.sumChargeRegurarly;
                investmentRegurarly.dataInvestment[i].deposit = deposit + investmentRegurarly.sumChargeRegurarly;
              } else {
                investmentRegurarly.dataInvestment[i].deposit = deposit;
              }
            }
          }
        }
        break;
      // typ kalkulace 'Splacený z prvních vkladů'
      case 4:
        investmentRegurarly.setHorizon(this.profileInvestment.horizon);
        investmentRegurarly.setSumCharge();
        investmentRegurarly.setSumChargeInflation(this.calculateInflation(investmentRegurarly.sumChargeRegurarly, this.profileInvestment.assumptionInflation, (investmentRegurarly.horizonStart - 1) + investmentRegurarly.horizon));
        let sum_charge = investmentRegurarly.sumChargeRegurarly;
        for (let i = 0; i < (investmentRegurarly.horizon + investmentRegurarly.horizonStart - 1); i++) {
          if (isNullOrUndefined(investmentRegurarly.dataInvestment[i])) {
            break;
          }
          // výpočet vkladu
          deposit = investmentRegurarly.calculateDepsit(i - investmentRegurarly.horizonStart + 2);
          if (((investmentRegurarly.horizon + investmentRegurarly.horizonStart) > i) && ((investmentRegurarly.horizonStart - 1) <= i)) {
            if (deposit !== 0) {
              charge = investmentRegurarly.regurarlyBruttoInvestment * (investmentRegurarly.sale.percentCharge / 100);
              if (sum_charge > charge) {
                investmentRegurarly.dataInvestment[i].charge = charge;
                sum_charge = sum_charge - charge;
              } else {
                charge = sum_charge;
                sum_charge = 0;
              }
              investmentRegurarly.dataInvestment[i].charge = charge;
            }

            investmentRegurarly.dataInvestment[i].deposit = deposit;
          }
        }
        break;
    }

    return investmentRegurarly;
  }

  calculatePaymentRent(horizon) {
    this.dataInvestment.splice(this.profileInvestment.horizon, this.dataInvestment.length);
    let PriceAll = this.dataInvestment[this.profileInvestment.horizon - 1].totalInvestment;
    let count_payment = horizon / (12 / this.profileInvestment.rent.periode);
    let paymentRent = PriceAll / count_payment;
    this.profileInvestment.rent.minRent = paymentRent;
    let rentHorizonCalculate = horizon;
    let delta_payment = 0;
    let max_payment = this.profileInvestment.rent.minRent * 2;
    let min_payment = this.profileInvestment.rent.minRent / 3;
    let RentPrev = 0;
    for (let i = 0; (i < maxCountCalculateRent); i++) {
      this.calculateHorizonRent();
      const lengthRent = (this.dataInvestment.length - 1) - this.profileInvestment.horizon;
      if (rentHorizonCalculate > lengthRent) {
        max_payment = this.profileInvestment.rent.minRent;
      } else if (rentHorizonCalculate === lengthRent) {
        if ((this.dataInvestment[this.dataInvestment.length - 1].cashout !== undefined) && (this.dataInvestment[this.dataInvestment.length - 2].cashout !== undefined)) {
          if (Math.round(this.dataInvestment[this.dataInvestment.length - 1].cashout) === Math.round(this.dataInvestment[this.dataInvestment.length - 2].cashout)) {
            break;
          }
        }
        if (this.dataInvestment[this.dataInvestment.length - 2].totalInvestment > this.profileInvestment.rent.minRent) {
          max_payment = this.profileInvestment.rent.minRent;
        } else {
          min_payment = this.profileInvestment.rent.minRent;
        }
      } else {
        min_payment = this.profileInvestment.rent.minRent;
      }
      RentPrev = this.profileInvestment.rent.minRent;
      this.profileInvestment.rent.minRent = min_payment + ((max_payment - min_payment) / 2);
      if (Math.round(this.dataInvestment[this.dataInvestment.length - 1].cashout) === 0) {
        this.dataInvestment.splice(this.dataInvestment.length - 1, this.dataInvestment.length);
      }
    }
  }

  calculatePaymentRentReal(horizon) {
    this.dataInvestment.splice(this.profileInvestment.horizon, this.dataInvestment.length);
    let PriceAll = this.dataInvestment[this.profileInvestment.horizon - 1].totalInvestment;
    let count_payment = horizon / (12 / this.profileInvestment.rent.periode);
    let paymentRent = PriceAll / count_payment;
    this.profileInvestment.rent.minRent = paymentRent;
    let rentHorizonCalculate = horizon;
    let delta_payment = 0;
    let max_payment = this.profileInvestment.rent.minRent * 2;
    let min_payment = this.profileInvestment.rent.minRent / 3;
    let RentPrev = 0;
    //for (let i = 0; (rentHorizonCalculate !== (this.dataInvestment.length - this.profileInvestment.horizon + (12 / this.profileInvestment.rent.periode))) && i < 20; i++) {
    //for ( let i = 0;  (i < 550) && (this.profileInvestment[this.dataInvestment.length - 1].cashout  !== this.profileInvestment[this.dataInvestment.length - 2].cashout ); i++) {

    for (let i = 0; (i < maxCountCalculateRent); i++) {
      this.calculateHorizonRent();
      const lengthRent = (this.dataInvestment.length - 1) - this.profileInvestment.horizon;
      //if ((rentHorizonCalculate > (this.dataInvestment.length - this.profileInvestment.horizon - (12 / this.profileInvestment.rent.periode)))) {
      if (rentHorizonCalculate > lengthRent) {
        max_payment = this.profileInvestment.rent.minRent;
      } else if (rentHorizonCalculate === lengthRent) {
        if ((this.dataInvestment[this.dataInvestment.length - 1].cashoutReal !== undefined) && (this.dataInvestment[this.dataInvestment.length - 2].cashoutReal !== undefined)) {
          if (Math.round(this.dataInvestment[this.dataInvestment.length - 1].cashoutReal) === Math.round(this.dataInvestment[this.dataInvestment.length - 2].cashoutReal)) {
            break;
          }
        }
        if (this.dataInvestment[this.dataInvestment.length - 2].totalInvestmentWithInflation > this.profileInvestment.rent.minRent) {
          max_payment = this.profileInvestment.rent.minRent;
        } else {
          min_payment = this.profileInvestment.rent.minRent;
        }
      } else {
        min_payment = this.profileInvestment.rent.minRent;
      }
      RentPrev = this.profileInvestment.rent.minRent;
      this.profileInvestment.rent.minRent = min_payment + ((max_payment - min_payment) / 2);
    }
    if (Math.round(this.dataInvestment[this.dataInvestment.length - 1].cashout) === 0) {
      this.dataInvestment.splice(this.dataInvestment.length - 1, this.dataInvestment.length);
    }
  }





  selectRegurarlyInvestment(index: number) {
    this.activeRegurarlyInvestment = index;
  }

  selectSingleInvestment(index: number) {
    this.activeSingleInvestment = index;
  }


  showSingleInvestmentCharge(i): number {
    return Math.round(this.profileInvestment.singleInvestments[i].charge);
  }

  showNettoSingleInvestment(i): number {
    return Math.round(this.profileInvestment.singleInvestments[i].nettoSingleInvestment);
  }


  addSingleInvestment() {
    if (this.profileInvestment.singleInvestments.length < 6) {
      this.profileInvestment.singleInvestments.push(new SingleInvestment());
      this.activeSingleInvestment = (this.profileInvestment.singleInvestments.length - 1);
    }
  }

  addRegurarlyInvestment() {
    if (this.profileInvestment.regurarlyInvestments.length < 4) {
      this.profileInvestment.regurarlyInvestments.push(new RegurarlyInvestment(this.profileInvestment.horizon));
      this.activeRegurarlyInvestment = (this.profileInvestment.regurarlyInvestments.length - 1);
    }
  }



  onGraph() {
    this.enabledGraph = true;
  }


  removeSingleInvestment(index: number) {
    this.profileInvestment.singleInvestments.splice(index, 1);
    this.activeSingleInvestment = this.profileInvestment.singleInvestments.length - 1;
    this.filldata();
  }


  removeRegurarlyInvestment(index: number) {
    this.profileInvestment.regurarlyInvestments.splice(index, 1);
    this.activeRegurarlyInvestment = this.profileInvestment.regurarlyInvestments.length - 1;
    this.filldata();
  }


  precisionRound(_number: number, precision: number): number {
    let factor = Math.pow(10, precision);
    return Math.round(_number * factor) / factor;
  }


  AmountFormat(value: any): String {
    Math.round(value);
    let _substrResult = '';
    let _substr = '';
    let _count: number;
    let _amount;
    if (value === undefined) {
      return '0';
    }
    const typ: string = typeof value;
    if (value instanceof String) {
      _amount = value;
    }
    else {
      _amount = value.toString();
    }
    let _countFloat = String((_amount.length) / 3);
    let a = _countFloat.split('.');
    let re = '/\.[0-9]+/gi';
    _count = Number(a[0]);
    const _countMod = (_amount.length) % 3;
    if (_amount.length > 3) {
      let _start_pos = -3;
      for (let i = 0; i < (_count); i++) {
        _substr = _amount.substr(_start_pos, 3);
        _start_pos -= 3;
        _substrResult = _substr + ' ' + _substrResult;
      }
      if (_countMod > 0) {
        _substr = _amount.substr(0, _countMod);
        _substrResult = _substr + ' ' + _substrResult;
      }
    }
    else {
      _substrResult = _amount;
    }
    return _substrResult + ' ' + currencyCharts[this.profileInvestment.currency];
  }

  calculateInflation(amount: number, inf: number, mounth: number): number {
    // tslint:disable-next-line:max-line-length
    return amount * Math.pow(1 - ((inf / 100) / (1 + (inf / 100))), mounth / 12);
  }

  updateData(event) {
    this.recalcInvestmentAll();
  }



  filldata() {
    this.enabledGraph = false;
    if (!isNullOrUndefined(this.graphComponent)) {
      this.graphComponent.updateChart();
    }
    // this.dataInvestment.splice(0, this.dataInvestment.length + 1);
  }







  /*
  * @requires $amount - investovaná částka
  * @requires $rate - zadaná úroková sazba
  * @requires $count_payment - počet úložek za úrokovací období
  * @requires $horizon - úrokovací období
  *
  * @function
  *
  * @description
  * Výpočet výsledné zúročené investované částky.
  *
  */
  calculateInvestmentInflation(amountNom: number, rate: number, count_payment: number): number {
    rate = rate / 100;
    //rate = (rate / (1 + rate));
    let Sum = Math.pow((1 - rate), count_payment * 12 / 12);
    return amountNom * Sum;
  }


  /*
    * @requires $investment - částka která ude investována pravidelně
    * @requires $rateInvestment - zhodnocení částky
    * @requires $modeCharge - typ placení poplatku
    * @function
    *
    * @description
    * Vrátí hodnotu poplatku
    *
    */
  calculateCharge(investment: number, rateInvestment: number, modeCharge: number): number {
    let sum_investment = 0;
    if (isNaN(rateInvestment) || (rateInvestment === 0) || isNaN(investment) || (investment === 0)) {
      return 0;
    }
    switch (modeCharge) {
      case 1:
        return ((investment * (rateInvestment / 100)));
      case 2:
        return ((investment * (rateInvestment / 100)) / (1 + (rateInvestment / 100)));
    }
  }

  // Výpočet reálného zhodnocení
  // nominalAppreciation : nominální zhodnocení
  // rateOfInflation : sazba inflace
  calculatEvaluation(nominalAppreciation: number, rateOfInflation: number): number {
    return (nominalAppreciation - rateOfInflation) / (1 + rateOfInflation);
  }



  public sumInvestmentSingle(countInvestment: number) {
    let sum_investment = 0;
    for (let u = 0; this.profileInvestment.singleInvestments.length > u; u++) {
      if (countInvestment === this.profileInvestment.singleInvestments[u].number_charge) {
        sum_investment += this.profileInvestment.singleInvestments[u].singleBruttoInvestment;
      }
    }
    return sum_investment;
  }

  sumCharge(countInvestment: number) {
    let _chargeCumulativelySingle = 0;
    for (let u = 0; this.profileInvestment.singleInvestments.length > u; u++) {
      if (this.profileInvestment.singleInvestments[u].number_charge <= countInvestment) {
        // tslint:disable-next-line:max-line-length
        _chargeCumulativelySingle += this.calculateCharge(this.profileInvestment.singleInvestments[u].singleBruttoInvestment, this.profileInvestment.singleInvestments[u].inputChargeSingleInvestment, this.profileInvestment.singleInvestments[u].chargeModeSingle);
      }
    }

    return _chargeCumulativelySingle;

  }




  // tslint:disable-next-line:max-line-length
  // paymentInvestment ... investovaná částka
  // nominalEvaluation ... výnos
  // rateInflation ... inflace
  // time ... zbytek
  // time2 ... pocet plateb
  // const_periode ... perioda plateb
  calculateInvestments(paymentInvestment: number, nominalEvaluation: number, rateInflation: number, time: number, time2: number, const_periode: number): number {
    let horizont = time2;

    if (time > 0) {
      let a = time2 - time;
      time2 = a / const_periode;
      time = time / (const_periode);
      time2 = (time2 + time);
    }
    else {
      time2 = time2 / const_periode;
    }

    // tslint:disable-next-line:max-line-length
    return (paymentInvestment * (Math.pow(1 + ((nominalEvaluation / 100) - (rateInflation / 100)) / (1 + (rateInflation / 100)), time2)));
  }


  /*
  * @requires $regurarlyBruttoInvestment - pravidelná platba
  * @requires $countYearRegurarlyInvestment - počet let investic v letech
  * @requires $const_periode - kolikrát v roce doachází k platbě
  * @requires $countInvestment - pocet měsíců investic
  * @requires $singleBruttoInvestment - součet jednorázových investice
  * @function
  *
  * @description
  * Výpočete součet všech plateb za měsíc $countInvestment
  *
  */
  calculatePayment(regurarlyBruttoInvestment: number,
    countYearRegurarlyInvestment: number, countInvestment: number, const_periode: number): number {
    let amountInvestment = 0;
    if ((countYearRegurarlyInvestment !== undefined) && (regurarlyBruttoInvestment !== undefined) && (regurarlyBruttoInvestment > 0)) {
      if ((12 / const_periode) <= countInvestment) {
        if (countYearRegurarlyInvestment >= countInvestment) {
          amountInvestment = this.dataInvestment[countInvestment - 1].payment + regurarlyBruttoInvestment;
        }
      }
    }
    return amountInvestment;
  }

  removeRent() {
    if (this.dataInvestment.length > this.profileInvestment.horizon) {
      this.dataInvestment.splice(this.profileInvestment.horizon - 1, this.dataInvestment.length);
    }
  }

  calculateHorizonRent() {
    if (this.dataInvestment.length > this.profileInvestment.horizon) {
      this.dataInvestment.splice(this.profileInvestment.horizon, this.dataInvestment.length + 1);
    }
    if (isNullOrUndefined(this.profileInvestment.rent.minRent)) {
      return;
    }
    let horizonRent = 0;
    let countRent = 0;
    let rent_real = this.profileInvestment.rent.minRent;
    let rent = 0;
    let rent_nominal = this.profileInvestment.rent.minRent;
    let _totalInvestment = (this.dataInvestment[this.dataInvestment.length - 1].totalInvestment);
    let _totalInvestmentWithInflation = (this.dataInvestment[this.dataInvestment.length - 1].totalInvestmentWithInflation);

    for (let u = 0; _totalInvestment > 0 && (u <= 1200); u++) {
      let dataRent = new DataInvestment();
      dataRent.cashout = 0;

      if (rent_nominal > _totalInvestment) {
        rent = _totalInvestment;
        dataRent.cashout = rent;
        if (((this.profileInvestment.rent.typeCalculation === 2) || (this.profileInvestment.rent.typeCalculation === 4)) && (this.calculatePeriode(u, this.profileInvestment.rent.periode))) {
          rent_nominal = _totalInvestment;
          //rent_real = this.calculateInvestments(rent_nominal, 0, this.profileInvestment.assumptionInflation, (u + this.profileInvestment.horizon - 1) % 12, u + this.profileInvestment.horizon - 1, 1);
          //rent_real = this.calculateInflation(rent_nominal, this.profileInvestment.assumptionInflation,  u + this.profileInvestment.horizon - 1);
          rent_real = this.calculateInvestments(rent_nominal, 0, this.profileInvestment.assumptionInflation, (u + this.profileInvestment.horizon - 1) % 12, u + this.profileInvestment.horizon - 1, 12);
          dataRent.cashoutReal = rent_real;
          dataRent.cashout = rent_nominal;
          dataRent.totalInvestmentWithInflation = 0;
          dataRent.totalInvestment = 0;
          dataRent.number_charge = u;
          dataRent.payment = this.dataInvestment[this.profileInvestment.horizon].payment;
          dataRent.payementInflation = this.calculateInflation(dataRent.payment, this.profileInvestment.assumptionInflation, u);
          console.log('renta h. vloyeno' + dataRent.payementInflation);
          horizonRent = u;
          this.dataInvestment.push(dataRent);
          break;
        }

      } else if (this.profileInvestment.rent.typeCalculation === 2 || this.profileInvestment.rent.typeCalculation === 4) {
        rent = rent_real;
      } else {
        rent = rent_nominal;
      }
      if (u === 0) {
        //_totalInvestment = _totalInvestment - rent;
        dataRent.cashoutReal = 0;
        dataRent.cashout = 0;
      }
      else {
        if (this.calculatePeriode(u, this.profileInvestment.rent.periode)) {
          if (_totalInvestment > rent) {
            if (u !== 1) {
            }
          } else {
            rent = _totalInvestment;
            if (this.profileInvestment.rent.typeCalculation === 2 || this.profileInvestment.rent.typeCalculation === 4) {
              rent_nominal = rent;
            } else {
              rent_nominal = rent;
            }
          }
          if (this.profileInvestment.rent.typeCalculation === 2 || this.profileInvestment.rent.typeCalculation === 4) {
            rent_nominal = this.calculateInvestments(rent, this.profileInvestment.assumptionInflation, 0, (u + this.profileInvestment.horizon - 1) % 12, (u + this.profileInvestment.horizon) - 1, 12);
            rent_real = rent;
          } else {
            rent_real = this.calculateInvestments(rent, 0, this.profileInvestment.assumptionInflation, (u + this.profileInvestment.horizon - 1) % 12, u + this.profileInvestment.horizon - 1, 12);
            rent_nominal = rent;
          }
          dataRent.cashoutReal = rent_real;
          dataRent.cashout = rent_nominal;
          _totalInvestment = _totalInvestment - rent_nominal;
          dataRent.number_charge = u + this.profileInvestment.horizon;

        }
        else {

          dataRent.cashoutReal = 0;
          dataRent.cashout = 0;
        }
        _totalInvestment = this.calculateInvestments(_totalInvestment, this.profileInvestment.rent.evaluationBrutto, 0, 1 % 12, 1, 12);
        _totalInvestmentWithInflation = this.calculateInvestments(_totalInvestment, 0, this.profileInvestment.assumptionInflation, (this.profileInvestment.horizon + u) % 12, (this.profileInvestment.horizon + u), 12);
      }

      // Výběr jedenřádek investic
      dataRent.totalInvestmentWithInflation = _totalInvestmentWithInflation;
      dataRent.totalInvestment = _totalInvestment;
      dataRent.number_charge = u;
      dataRent.payment = this.dataInvestment[this.profileInvestment.horizon - 1].payment;
      dataRent.payementInflation = this.calculateInflation(dataRent.payment, this.profileInvestment.assumptionInflation, this.profileInvestment.horizon + u);
      horizonRent = u;
      if ((dataRent.cashout === this.profileInvestment.rent.minRent) && (this.profileInvestment.rent.typeCalculation === 1)) {
        countRent++;
      }
      if ((dataRent.cashoutReal === this.profileInvestment.rent.minRent) && (this.profileInvestment.rent.typeCalculation === 2)) {
        countRent++;
      }
      if ((dataRent.cashoutReal === this.profileInvestment.rent.minRent) && (this.profileInvestment.rent.typeCalculation === 4)) {
        countRent = this.profileInvestment.rent.rentCalculateHorizon;
      }
      if ((dataRent.cashout === this.profileInvestment.rent.minRent) && (this.profileInvestment.rent.typeCalculation === 3)) {
        countRent = this.profileInvestment.rent.rentCalculateHorizon;
      }
      if (u !== 0) {
        this.dataInvestment.push(dataRent);
      }
    }

    this.profileInvestment.rent.countRent = countRent;
    this.profileInvestment.rent.horizon = horizonRent;

  }




  /*
    * @requires $number_charge - číslo platby po měsících
    * @requires $const_periode - kolikrát v roce doachází k praidelné platbě
    * @function
    *
    * @description
    * Výpočete součet všechn jednorázových plateb k danému měsíci  $number_charge
    *
    */
  calculateInvestmentsSingleAll(number_charge: number, const_periode: number): number {
    let _singleInvestment = 0;
    for (let u = 0; this.profileInvestment.singleInvestments.length > u; u++) {
      if (this.profileInvestment.singleInvestments[u].number_charge <= number_charge) {
        // tslint:disable-next-line:max-line-length
        _singleInvestment += this.calculateInvestments(this.profileInvestment.singleInvestments[u].singleBruttoInvestment - this.calculateCharge(this.profileInvestment.singleInvestments[u].singleBruttoInvestment, this.profileInvestment.singleInvestments[u].inputChargeSingleInvestment, this.profileInvestment.singleInvestments[u].chargeModeSingle), this.profileInvestment.assumptionEvaluation, 0, (number_charge - this.profileInvestment.singleInvestments[u].number_charge - 1) % const_periode, (number_charge - this.profileInvestment.singleInvestments[u].number_charge + 1), const_periode);
      }
    }
    return _singleInvestment;
  }

  calculatePeriode(countHorizon: number, periode: number): boolean {
    if (countHorizon === 1) {
      return true;
    } else if (((countHorizon - 1) % ((12 / periode)) === 0)) {
      return true;
    } else {
      return false;
    }
  }





  openDialog(): void {
    const dialogRef = this.dialog.open(DialogCheckColumns, {
      width: '500px',
      // height: '600px',
      data: {
        profileInvestment: this.profileInvestment,
        dataInvestment: this.dataInvestment
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.checkColumns = result.data.checkColumns;
    });


  }



}





@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-ckeck-columns',
  templateUrl: './checkColumnDialog.component.html',
  styleUrls: ['./checkColumnDialog.component.css']
})

export class DialogCheckColumns {
  pdf_downloads = false;
  checkExport = new CheckExport();
  public keyUpClose = new Subject<string>();
  constructor(
    public dialogRef: MatDialogRef<DialogCheckColumns>,
    @Inject(MAT_DIALOG_DATA) public data: any, private pdfservice: PdfService) {
    this.checkExport.checkColumns.setAllInvestment();
    const subscription = this.keyUpClose
      //.map(event => event.target.value)
      //.subscribe(() => {this.recalcInvestmentAll(); });
      .pipe(
        debounceTime(1000)
      )
      .pipe(distinctUntilChanged())
      .pipe(delay(500)
      ).subscribe(() => { this.dialogRef.close(); });
  }


  createPdf(): void {
    this.pdf_downloads = true;
    this.pdfservice.makePdf( this.data.profileInvestment, this.data.dataInvestment, this.checkExport);
  }

  close(): void {
    this.dialogRef.close();
  }





}








