import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtrAmount'
})
export class AmountPipe implements PipeTransform {
  transform(value: any): string {
    let _substrResult = '';
    let _substr = '';
    let _count: number;
    let _amount;
    if (isNaN(value)) {
      return '0';
    }
    const typ: string = typeof value;
    if (value instanceof String) {
      _amount = value;
    }
    else {
      _amount = value.toString();
    }
    let _countFloat = String((_amount.length) / 3);
    let delay = _countFloat.split('.');
    _count = Number(delay[0]);
    const _countMod = (_amount.length) % 3;
    if (_amount.length > 3) {
      let _start_pos = -3;
      for (let i = 0; i < (_count); i++) {
        _substr = _amount.substr(_start_pos, 3);
        _start_pos -= 3;
        _substrResult = _substr + ' ' + _substrResult;
      }
      if (_countMod > 0) {
        _substr = _amount.substr(0, _countMod);
        _substrResult = _substr + ' ' + _substrResult;
      }
    }
    else {
      _substrResult = _amount;
    }

    _substrResult = _substrResult.replace(/\s\./, '.');
   // _substrResult = _substrResult.replace(/^(.*)\.(\d)$/, '$1.$2 0');
    return _substrResult;
  }
}
