import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'spaceHtml', pure: false })
export class SpaceHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(content: string) {
    let re = / /gi;
    let newstr = content.replace(re, "&nbsp;");
    return this.sanitizer.bypassSecurityTrustHtml(newstr);
  }
}
