import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {Observable} from "rxjs";

import {AuthService} from "./auth.service";
import {finalize, tap} from "rxjs/operators";

@Injectable()
export class HttpApiInterceptor implements HttpInterceptor {
  constructor(public authService: AuthService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.getAccessToken()}`
      }
    });
  //  return next.handle(request);
 // }
    if (request.url.match(/^\/api/)) {
    console.log('test' + this.authService.getAccessToken());
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.getAccessToken()}`
        }
      });
    }
    let unauthorized: boolean = false;
    return next.handle(request).pipe(
      tap(event => unauthorized = event instanceof HttpResponse ? false : true,
        error => unauthorized = error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)),
      finalize(() => {
        if (unauthorized) {
        this.authService.logout();
       // this.router.navigate(['/']);
        }
      }));
  }
}
