export class CanvasPdf {


  chart_axis_y_beginning: number;

  chart_axis_x_beginning: number;

  chart_axis_y_end: number;

  chart_axis_x_end: number;

  chart_axis_y_count: number;

  chart_axis_x_padding_value: number;

  chart_axis_y_beginning_vertical: number;

  chart_axis_x_beginning_vertical: number;

  chart_axis_y_end_vertical: number;

  chart_axis_x_end_vertical: number;

  chart_x_max: number;

  chart_y_max: number;

  chart_x_unit: number;

  chart_y_unit: number;

  chart_axis_periode: number;

  chart_axis_x_label: string;


  chart_axis_unit_periode: number;

  chart_axis_y_value: number;

  orientation: OrientationPdf;

  constructor( orientationPdf: OrientationPdf){
    this.chart_axis_y_count = 10;
    this.chart_axis_periode = 1;
    this.setOrientation(orientationPdf);
  }


setPeriode( all_unit: number) {
  all_unit = all_unit / 12;
  if (all_unit >= 18) {
    this.chart_axis_periode = 5;
  } else if ( (12  > all_unit) && ( all_unit < 18 ) ) {
    this.chart_axis_periode = 2;
  } else if ( (0  > all_unit) && ( all_unit < 12 )) {
    this.chart_axis_periode = 1;
  }
}



setUnitPeriode(all_unit: number){
   if (all_unit <  48) {
    this.chart_axis_unit_periode = 1;
    this.chart_axis_x_label = 'Měsíce';
   } else {
    this.chart_axis_unit_periode = 12;
    this.chart_axis_x_label = 'Roky';
   }
}



setOrientation( orientation: OrientationPdf) {
  if ( orientation === OrientationPdf.portrait) {
    this.chart_axis_y_beginning = 45;
    this.chart_axis_x_beginning = 0;
    this.chart_axis_y_end = 250;
    this.chart_axis_x_end = 410;
    this.chart_axis_y_count = 10;
    this.chart_axis_x_padding_value = 0;

    this.chart_axis_y_beginning_vertical = 45;
    this.chart_axis_x_beginning_vertical = 65;
    this.chart_axis_periode = 5;
   this.orientation = OrientationPdf.portrait;

  } else {
    this.chart_axis_y_beginning = 45;
    this.chart_axis_x_beginning = 70;
    this.chart_axis_y_end = 355;
    this.chart_axis_x_end = 730;
    this.chart_axis_y_count = 10;
    this.chart_axis_x_padding_value = 0;

    this.chart_axis_y_beginning_vertical = 45;
    this.chart_axis_x_beginning_vertical = 65;

    this.chart_axis_periode = 1;
   this.orientation = OrientationPdf.landscape;
  }
}



setPaddingX(number_length: number) {
  if ( number_length > 4 ) {
    this.chart_axis_x_beginning -= 2;
  }
  if ( number_length > 8 ) {
    this.chart_axis_x_beginning -= 4;
  }
  const space = 3;
  this.chart_axis_x_padding_value = number_length * space - 20;
  this.chart_axis_x_beginning += this.chart_axis_x_padding_value;

}

setMaxY(max_y) {
  this.chart_y_max = max_y;
}



caclculateAxisY() {
  const string_value = Math.round(this.chart_y_max).toString();
  const length_value = (string_value.length - 1);
  const first_value = Math.round(this.chart_y_max / (Math.pow(10, length_value)));
  const amount_all = first_value * (Math.pow(10, length_value));
  this.chart_axis_y_value = amount_all / this.chart_axis_y_count;
}







}

export enum OrientationPdf {
  'landscape' = 1,
  'portrait' = 2
}
