import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from '../auth/auth.service';
import {User} from './user';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getUserInfo( token: string): Promise<User> {
      return   this.http.get( environment.issuer + 'api/v1/users/me/')
    .toPromise()
      .then(response => response as User);
  }

}
