import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  public static status: string;
  public static message: string;
  public status =  '404';
  public title = 'Page not found' ;

  constructor() {
    if (ErrorComponent.message !== undefined) {
      this.title = ErrorComponent.message;
      this.status = ErrorComponent.status;
    }
  }

  ngOnInit() {
  }

}
