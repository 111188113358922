import { Component, OnInit, Input, Inject } from '@angular/core';
import { DataInvestment } from './dataInvestment';
import { Profile } from '../profile/profile';
import { CheckColumns } from '../profile/checkColumns';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { isNullOrUndefined } from 'util';


@Component({
  selector: 'app-data-investment',
  templateUrl: './data-investment.component.html',
  styleUrls: ['./data-investment.component.css']
})
export class DataInvestmentComponent implements OnInit {

  @Input() public profile: Profile;
  @Input() public checkColumns: CheckColumns;
  currentYear: number;
  height_data_table: number;
  show_column: number;
  device_small: boolean;



  @Input() public dataInvestmentTable: DataInvestment[];
  constructor(public dialog: MatDialog) {


  }



  checkChart(): boolean {
    /*if (( ( this.dataInvestmentTable.length -  this.profile.rent.horizon) ===   this.profile.horizon ) && ( this.profile.rent.on)) {
     return true;
    } else if ( (( this.dataInvestmentTable.length) ===  this.profile.horizon  ) && (!this.profile.rent.on) ) {
      return true;
    } else {
      return false;
    }*/
    return true;
  }

  ngOnInit() {
    if (window.screen.width <= 1200 ) {
      this.show_column = 1;
      this.device_small = true;
      this.checkColumns.setOnlySingleInvestment();
    }else {
      this.device_small = false;
      this.show_column = 4;
    }
    //this.checkColumns.detailHorizon = false;
    /*let a = document.getElementById('panel-title-tabs');
    let height_panel = a.offsetHeight;
    let a = document.getElementById('data-investment-table').setAttribute.;
    let now = new Date();
    let height = window.screen.height;
    let width = window.screen.width;
    console.log(height_panel);
    this.height_data_table = height - height_panel - 335;
    if ( width < 900 ) {
      this.checkColumns.checkCharge = false;
      this.checkColumns.checkPayementInflation = false;
      this.checkColumns.checkSingleInvestmentWithInflation = false;
      this.checkColumns.checkRegurarlyInvestmentWithInflation = false;
    }*/

    const now = new Date();
    this.currentYear = Number(now.getFullYear());
  }

  isDataInvestmentRegurarly(): boolean{
    if ( isNullOrUndefined(this.profile.regurarlyInvestments)){
      return false;
    } else if (this.profile.regurarlyInvestments.length === 0){
      return false;
    } else{
      return true;
    }
  }


  isDataInvestmentSingle(): boolean{
    if ( isNullOrUndefined(this.profile.singleInvestments)){
      return false;
    } else if (this.profile.singleInvestments.length === 0){
      return false;
    } else{
      return true;
    }
  }


  isCharge() {
    if ( isNullOrUndefined(this.dataInvestmentTable[this.profile.horizon - 1])) {
      return false;
    }
    if ( isNullOrUndefined(this.dataInvestmentTable[this.profile.horizon - 1].chargeCumulatively)) {
      return false;
    }
    const charge  = this.dataInvestmentTable[this.profile.horizon - 1].chargeCumulatively;
   if (charge > 0) {
      return true;
    } else {
      return false;
    }

  }



  showYearCharge(indexYear: number): number {
    let chargeSum = 0;
    if (this.checkColumns.detailHorizon) {
      return this.dataInvestmentTable[indexYear].charge;
    } else {
      for (let u = 0; 12 > u; u++) {
        if ( this.dataInvestmentTable[indexYear - u] === undefined ) {
          break;
          }
        if ( this.dataInvestmentTable[indexYear - u].charge !== undefined ) {
        chargeSum += this.dataInvestmentTable[indexYear - u].charge;
        }
      }
    }
    return chargeSum;
  }



   isInt(n: any){
    return Number(n) === n && n % 1 === 0;
}

  showYear(index): any {

    if (this.checkColumns.detailHorizon) {
      return index + 1;
    } else {
      const índex_year = ((index + 1) / 12);
      if ( this.isInt(índex_year)) {
        return ((index + 1) / 12);

      } else {
        const residue = index % 12;
        return ((index - residue) / 12) + 1;
       }
    }
  }

  checkDetail(index: number): boolean {
    //if ((index % this.profile.periode) === 0) {
    if (index === 0) {
      return false;
    }
    if (((index + 1) % 12) === 0) {
      return true;
    }
    return false;
  }
}









