export class CheckColumns {

  // detail horizontu
  detailHorizon: boolean;

  // číslo platby
  checkNumberCharge: boolean;

  // Historicky vloženo
  checkPayment: boolean;

  // Vklad s inflací
  checkPayementInflation: boolean;

  // Poplatky zaplacené v danné platbě
  checkCharge: boolean;

  // Zaplacené poplatky kumulativně
  checkChargeCumulatively: boolean;

  // Zaplacené poplatky v jednom horizontu
  checkchargeHorizont: boolean;

  // Průběh jednorázové investice
  checkSingleInvestment: boolean;

  // Průběh jednorázové investice s inflací
  checkSingleInvestmentWithInflation: boolean;

  // Průběh pravidelné investice
  checkRaegurarlyInvestment: boolean;

  // Průběh pravidelné investice s inflací
  checkRegurarlyInvestmentWithInflation: boolean;

  // Průběh investice celkem
  checkTotalInvestment: boolean;

  // Průběh  investice s inflací celkem
  checkTotalInvestmentWithInflation: boolean;

  constructor(){
      // detail horizontu
  this.detailHorizon = false;

  // číslo platby
  this.checkNumberCharge =  true;

  // Historicky vloženo
  this.checkPayment =  true;

  // Vklad s inflací
  this.checkPayementInflation =  true;

  // Poplatky zaplacené v danné platbě
  this.checkCharge = true;

  // Zaplacené poplatky kumulativně
  this.checkChargeCumulatively = true;

  // Zaplacené poplatky v jednom horizontu
  this.checkchargeHorizont = true;

  // Průběh jednorázové investice
  this.checkSingleInvestment = true;

  // Průběh jednorázové investice s inflací
  this.checkSingleInvestmentWithInflation = true;

  // Průběh pravidelné investice
  this.checkRaegurarlyInvestment = true;

  // Průběh pravidelné investice s inflací
  this.checkRegurarlyInvestmentWithInflation = true;

  // Průběh investice celkem
  this.checkTotalInvestment = true;

  // Průběh  investice s inflací celkem
  this.checkTotalInvestmentWithInflation = true;
  }

showYear(index): any {

    if (this.detailHorizon) {
      //return String(this.currentYear) + " /  00";
      return index + 1;
    } else {
      return ((index + 1) / 12); //(12 / this.profile.periode));
    }

  }

  setOnlySingleInvestment(){

      // číslo platby
      this.checkNumberCharge =  true;

      // Historicky vloženo
      this.checkPayment =  true;

      // Vklad s inflací
      this.checkPayementInflation =  true;

      // Poplatky zaplacené v danné platbě
      this.checkCharge = false;

      // Zaplacené poplatky kumulativně
      this.checkChargeCumulatively = false;

      // Zaplacené poplatky v jednom horizontu
      this.checkchargeHorizont = false;

      // Průběh jednorázové investice
      this.checkSingleInvestment = true;

      // Průběh jednorázové investice s inflací
      this.checkSingleInvestmentWithInflation = true;

      // Průběh pravidelné investice
      this.checkRaegurarlyInvestment = false;

      // Průběh pravidelné investice s inflací
      this.checkRegurarlyInvestmentWithInflation = false;

      // Průběh investice celkem
      this.checkTotalInvestment = true;

      // Průběh  investice s inflací celkem
      this.checkTotalInvestmentWithInflation = true;
  }
  setAllInvestment(){
    // číslo platby
    this.checkNumberCharge =  true;

    // Historicky vloženo
    this.checkPayment =  true;

    // Vklad s inflací
    this.checkPayementInflation =  true;

    // Poplatky zaplacené v danné platbě
    this.checkCharge = true;

    // Zaplacené poplatky kumulativně
    this.checkChargeCumulatively = true;

    // Zaplacené poplatky v jednom horizontu
    this.checkchargeHorizont = true;

    // Průběh jednorázové investice
    this.checkSingleInvestment = true;

    // Průběh jednorázové investice s inflací
    this.checkSingleInvestmentWithInflation = true;

    // Průběh pravidelné investice
    this.checkRaegurarlyInvestment = true;

    // Průběh pravidelné investice s inflací
    this.checkRegurarlyInvestmentWithInflation = true;

    // Průběh investice celkem
    this.checkTotalInvestment = true;

    // Průběh  investice s inflací celkem
    this.checkTotalInvestmentWithInflation = true;
}

  setOnlyRegurarlyInvestment(){
    // číslo platby
    this.checkNumberCharge =  true;

    // Historicky vloženo
    this.checkPayment =  true;

    // Vklad s inflací
    this.checkPayementInflation =  true;

    // Poplatky zaplacené v danné platbě
    this.checkCharge = false;

    // Zaplacené poplatky kumulativně
    this.checkChargeCumulatively = false;

    // Zaplacené poplatky v jednom horizontu
    this.checkchargeHorizont = false;

    // Průběh jednorázové investice
    this.checkSingleInvestment = false;

    // Průběh jednorázové investice s inflací
    this.checkSingleInvestmentWithInflation = false;

    // Průběh pravidelné investice
    this.checkRaegurarlyInvestment = true;

    // Průběh pravidelné investice s inflací
    this.checkRegurarlyInvestmentWithInflation = true;

    // Průběh investice celkem
    this.checkTotalInvestment = true;

    // Průběh  investice s inflací celkem
    this.checkTotalInvestmentWithInflation = true;
}





setOnlyChargeInvestment(){

  // číslo platby
  this.checkNumberCharge =  true;

  // Historicky vloženo
  this.checkPayment =  true;

  // Vklad s inflací
  this.checkPayementInflation =  true;

  // Poplatky zaplacené v danné platbě
  this.checkCharge = true;

  // Zaplacené poplatky kumulativně
  this.checkChargeCumulatively = true;

  // Zaplacené poplatky v jednom horizontu
  this.checkchargeHorizont = true;

  // Průběh jednorázové investice
  this.checkSingleInvestment = false;

  // Průběh jednorázové investice s inflací
  this.checkSingleInvestmentWithInflation = false;

  // Průběh pravidelné investice
  this.checkRaegurarlyInvestment = false;

  // Průběh pravidelné investice s inflací
  this.checkRegurarlyInvestmentWithInflation = false;

  // Průběh investice celkem
  this.checkTotalInvestment = true;

  // Průběh  investice s inflací celkem
  this.checkTotalInvestmentWithInflation = true;
}
getTitleHorizon(): string {
  if ( this.detailHorizon) {
    return 'Konec měsíce č.';
  } else {
    return 'Konec roku č.';
  }
}


}
