import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ProfileComponent } from './profile/profile.component';
import { LoginComponent } from './login/login.component';
import {LoggedUserGuard} from './auth/logged-user.guard';

import { LogoutComponent } from './logout/logout.component';
import { ErrorComponent } from './error/error.component';


const routes: Routes = [
  { path: '',   component: LoginComponent },
  { path: 'logout',   component: LogoutComponent },
  { path: 'error',   component: ErrorComponent },
  { path: 'start',   component: ProfileComponent, canActivate: [LoggedUserGuard] },
  { path: '**',   pathMatch: 'full', component: ErrorComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
