import { Sale } from './sale';
import { periodeEnum } from './periodeEnum';
import { DataInvestmentRegurarly } from './dataInvestmentRegurarly';
import { calculationChargeEnum } from './calculationChargeEnum';



export class RegurarlyInvestment {

  // stav validace
  valid;

  // délka pravidelné investice
  horizon;

  // měsíc kterým  začne pravidelná investice
  horizonStart: number;

  // poplatek pravidelné investice
  charge: number;

  // Pravidelná brutto investice:
  regurarlyBruttoInvestment: number;

  // Frekvence platby poplatků
  frequencyRegurarlyCharge:  frequencyRegurarlyCharge;

  // typ poplatku
  typeChargeRegurarly: typeChargeRegurarly;

  // způsob výpočtu poplatku
  calculationRegurarlyCharge: calculationChargeEnum;

  // Výše poplatku
  chargeAmountRegurarly: number;

  // Výše poplatku z investované částky
  chargeFromInvestment: number;

  // Netto pravidelná investice
  investmentNettoAmount: number;

  // Perioda pravidelne investice
  periode: periodeEnum;

  // tabulka investovaných částek
  dataInvestment: DataInvestmentRegurarly[] = [];

  // typ výpočtu poplatku
  chargeModeRegurarly: chargeModeEnum;

  // tabulka slev pro danou pravidelnou investici
  sale: Sale;

  // Každoroční valorizace pravidelných vkladů
  valorizationPercent: number;

  // vypočítat poplatek
  calculateSaleSingleInvestment: number;

  // Celkový poplatek za dobu trvání pravidelného vkladu
  sumChargeRegurarly: number;

  // Celkový poplatek za dobu trvání pravidelného vkladu s inflací
  sumChargeRegurarlyWithInflation: number;

  // počet poplatků
  ChargeCount: number;

  horizonStartMax: number;



  constructor(horizon: number) {
    this.horizon = horizon;
    this.charge = 0;
    //this.regurarlyBruttoInvestment = 0;
    //this.chargeModeRegurarly = 1;
    // Perioda pravidelne investice
    this.periode = 12;
    this.sale = new Sale();
    this.horizonStartMax = 360;
    this.frequencyRegurarlyCharge = 1;
    this.calculationRegurarlyCharge = 2;
    this.valid = false;
    this.horizonStart = 1;
    this.valorizationPercent = 0;
    //this.valorizationOn = true;
    //this.valorizationPercent = 5;
  }

  removeTypeCharge(){
    this.chargeModeRegurarly = undefined;
    this.typeChargeRegurarly = undefined;
  }

  setSumCharge(){
    if ( ( this.typeChargeRegurarly === 3) || (this.typeChargeRegurarly === 4) ) {
      this.sumChargeRegurarly = this.calculateSumCharge();
    } else {
      this.sumChargeRegurarly = this.sumCharge();
    }
  }

  setMaxStart(horizonProfile: number) {
    if (isNaN(horizonProfile)) {
      this.horizonStartMax = 420;
    }else if ( isNaN(this.horizonStart)) {
      this.horizonStartMax = horizonProfile + 1;
    }else {
      this.horizonStartMax = horizonProfile - this.horizonStart + 1;
    }
    this.checkValidationHorzon(horizonProfile);

  }


  checkValidationHorzon(horizonProfile: number) {
      if ( horizonProfile <  (this.horizon +  this.horizonStart - 1) ) {
        this.valid = false;
      } else {
        this.valid = true;
      }
  }


  setSumChargeInflation(sumChargeInflation: number){
    this.sumChargeRegurarlyWithInflation = sumChargeInflation;
  }

  sumCharge(): number {
    let chargeSum = 0;
    for (const investment of this.dataInvestment) {
      chargeSum += investment.charge;
    }
    return chargeSum;

  }

  setValid(valid: boolean){
    this.valid = valid;
  }


  getCountCharge(): number {
    let chargeCount = 0;
    for (const investment of this.dataInvestment) {
      if (investment.charge !== 0){
      chargeCount++;
      }
    }
    return chargeCount;
  }



  sumDeposit(horizon: number): number {
    let depositSum = 0;
    for (let count = 0; horizon >= count; count++) {
      if ( this.dataInvestment[count].deposit !== undefined ) {
      depositSum += this.dataInvestment[count].deposit;
      }
    }
    return depositSum;

  }


  calculateSumCharge(): number{
    let invest = 0;
    if (this.calculationRegurarlyCharge === 1) {
       invest = this.regurarlyBruttoInvestment * (this.chargeFromInvestment / 100);
    } else {
      invest = ((this.regurarlyBruttoInvestment * (this.chargeFromInvestment / 100)) / (1 + (this.chargeFromInvestment / 100)) );

    }
    return  (((invest / 12) * this.periode) * this.horizon);
  }


  selecTypeRegurarlyCharge() {
   if ( this.typeChargeRegurarly === undefined) {
    this.chargeModeRegurarly = undefined;
   }
    if (this.frequencyRegurarlyCharge === 1) {
      if (this.typeChargeRegurarly === 1  ) {
        if (this.calculationRegurarlyCharge === 2) {
          if (this.chargeModeRegurarly !== 1 ) {
          }
          this.chargeModeRegurarly = 0;
        } else {
          if (this.chargeModeRegurarly !== 0 ) {
          }
          this.chargeModeRegurarly = 1;
        }
      } else {
        if (this.calculationRegurarlyCharge === 2) {
          this.chargeModeRegurarly = 2;
        } else {
          this.chargeModeRegurarly = 3;
        }
      }
    } else {
      if (this.typeChargeRegurarly === 3) {
        if (this.calculationRegurarlyCharge === 2) {
          this.chargeModeRegurarly = 4;
        } else {
          this.chargeModeRegurarly = 5;
        }
      } else {
        if (this.calculationRegurarlyCharge === 2) {
          this.chargeModeRegurarly = 8;
        } else {
          this.chargeModeRegurarly = 9;
        }
      }
    }
  }




  selectModeRegurarlyCharge() {
   if ( this.typeChargeRegurarly === undefined) {
    this.chargeModeRegurarly = undefined;
   }
    if (this.frequencyRegurarlyCharge === 1) {
      if (this.typeChargeRegurarly === 1  ) {
        if (this.calculationRegurarlyCharge === 2) {
          this.chargeFromInvestment = undefined;
          this.valid = false;
          this.chargeModeRegurarly = 0;
        } else {
          this.chargeFromInvestment = undefined;
          this.valid = false;
          this.chargeModeRegurarly = 1;
        }
      } else {
        if (this.calculationRegurarlyCharge === 2) {
          this.chargeModeRegurarly = 2;
        } else {
          this.chargeModeRegurarly = 3;
        }
      }
    } else {
      if (this.typeChargeRegurarly === 3) {
        if (this.calculationRegurarlyCharge === 2) {
          this.chargeFromInvestment = undefined;
          this.valid = false;
          this.chargeModeRegurarly = 4;
        } else {
          this.chargeFromInvestment = undefined;
          this.valid = false;
          this.chargeModeRegurarly = 5;
        }
      } else {
        if (this.calculationRegurarlyCharge === 2) {
          this.chargeFromInvestment = undefined;
          this.valid = false;
          this.chargeModeRegurarly = 8;
        } else {
          this.chargeFromInvestment = undefined;
          this.valid = false;
          this.chargeModeRegurarly = 9;
        }
      }
    }
 }


  /*
    * @function
    *
    * @description
    * Nastaví počet řádků podle doby pravydelné investice
    *
    */
  setHorizon(horizon: number){
    this.dataInvestment.splice(0, this.dataInvestment.length);
    for (let i = 0; i < horizon; i++){
      this.dataInvestment.push(new DataInvestmentRegurarly());
    }
  }

  /*
    * @requires $ivestmentValue - hodnota investované částky
    * @function
    *
    * @description
    * Vrátí procento poplatku pro danou investici
    *
    */
  calculateSale(ivestmentValue: number): number {
    for (let countSale = 0; countSale < this.sale.interval.length; countSale++) {
      // tslint:disable-next-line:max-line-length
      if ((this.sale.interval[countSale].begin <= ivestmentValue) && (ivestmentValue <= this.sale.interval[countSale].end)) {
        return this.sale.interval[countSale].percent;
      }
    }
    return this.sale.interval[this.sale.interval.length - 1].percent;
  }

  /*
    * @requires $amount - částka která se má vkládat v každém období
    * @requires $rate - zhodnocení částky
    * @requires $count_payment - počet plateb
    * @function
    *
    * @description
    * Vrátí procento poplatku pro danou investici
    *
    */
    calculateInvestment(amount: number, rate: number, count_payment: number) {
      rate = (rate / 100) / 12;
      let Sum = (amount * (1 + rate)) * (((Math.pow(1 + rate, count_payment) - 1) / rate));
      return Sum;
    }




  /*
    * @requires $regurarlyInvestment -
    * @function
    *
    * @description
    * Vypočítej vklad pro dané období měsíce
    *
    */
  // tslint:disable-next-line:one-line
  calculateDepsit(countDeposit: number): number{
    //Výpočet vkladu
    let deposit = 0;
    if (this.isDeposit(countDeposit)){
      deposit =  this.calculateValorization(this.regurarlyBruttoInvestment, this.valorizationPercent, countDeposit);
      }
    return deposit;
  }

    /*calculatePeriode(countHorizon: number, periode: number): boolean {

    }*/

  /*
    * @requires $paymentRegularlyInvestment - zaplacená částka pravidelně
    * @requires $valorizationPercent - procento valorizace za rok
    * @requires $countDeposit - počet počet měsícu vkladů (měsíce)
    * @function
    *
    * @description
    * Vypočte valorizovanou částku
    *
    */
   calculateValorization(paymentRegularlyInvestment: number, valorizationPercent: number, countDeposit: number) {
    let amountValorization = paymentRegularlyInvestment;
    if ( (!isNaN(this.valorizationPercent) || ( this.valorizationPercent > 0))  && (countDeposit > 12) ) {
      const countValorization = countDeposit / 12;
      for (let i = 1; i < countValorization ; i++ ) {
        amountValorization = amountValorization + ((amountValorization / 100) * valorizationPercent);
      }
       return amountValorization;
    }
    return amountValorization;
  }



  /*
    * @requires countDeposit: number  číslo vkladu
    * @function
    *
    * @description
    * Vrátí true pokud je období platby
    *
    */
    isDeposit(countDeposit: number): boolean{
        if (countDeposit === 1) {
          return true;
        } else if (((countDeposit - 1) % ((12 / this.periode)) === 0)) {
          return true;
        } else {
          return false;
        }
      }



    }





export enum frequencyRegurarlyCharge {
  'Průběžný' = 1,
  'Předplacený' = 2,
}

export enum typeChargeRegurarly {
  'Konstantní' = 1,
  'Se slevou na objem' = 2,
  'Placený zvlášť s prvním vkladem' = 3,
  'Splacený z prvních vkladů' = 4,
}


export enum chargeModeEnum {
  'Průběžný poplatek vypočtený z brutto platby' = 0,
  'Průběžný poplatek vypočtený z netto investované částky' = 1,
  'Průběžný poplatek vypočtený z netto vkladu se slevou za historicky dosažený objem' = 2,
  'Průběžný poplatek vypočtený z brutto vkladu se slevou za historicky dosažený objem' = 3,
  'Předplacený poplatek vypočtený z netto celkového vkladu a placený zvlášť s prvním vkladem' = 4,
  'Předplacený poplatek vypočtený z brutto celkového vkladu a placený zvlášť s prvním vkladem' = 5,
  'Předplacený poplatek vypočtený z netto celkového vkladu a splácený z prvních vkladů' = 6,
  'Předplacený poplatek vypočtený z brutto celkového vkladu a splácený z prvních vkladů' = 7,
  'Předplacený poplatek vypočtený z netto celkového vkladu a splácený z prvních vkladů v poměru X:Y' = 8,
  'Předplacený poplatek vypočtený z brutto celkového vkladu a splácený z prvních vkladů v poměru X:Y' = 9
}
