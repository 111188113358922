import {Injectable} from '@angular/core';
import {SessionStorageService} from 'ngx-webstorage';


@Injectable({
  providedIn: 'root'
})
export class AuthStorageService {

  private SESSION_KEY = 'calc';

  constructor(private sessionStorage: SessionStorageService) {
  }

  save(at: string) {
    this.sessionStorage.store(this.SESSION_KEY, at);
  }

  getToken(): string {
    return this.sessionStorage.retrieve(this.SESSION_KEY);
  }

  clear(): void {
    this.sessionStorage.clear(this.SESSION_KEY);
  }
}
