declare const require: any;
export const environment = {
  production: true,
  VERSION: require('../../package.json').version,
  // server s Oauthem 2
  issuer: 'https://sol.vatta.cz/',
  // login url pro identifikaci poskytovatelem
  loginUrl: 'https://sol.vatta.cz/o/authorize/',
  // ulr aplikace po přihlášení
  redirectUri : 'https://dar.vatta.cz/start',
  // id klientské aplikace  - identifikační id poskytovatele
  clientId: 'f5ImINGH8T6jQFGfBYPqDPmBND3rFbppc4DNI7fC',
  // Revoke token
  revokeToken: 'https://sol.vatta.cz/o/revoke_token/',
  // url of the system analyzer
  urlAnalyzer: 'https://terra.vatta.cz',
  // url of the system core
  urlCore: 'https://sol.vatta.cz',
  // url of the system wiki
  urlWiki: 'https://wiki.vatta.cz',

  // PDF brand
  pdfColorTextSection: '#525659',
  pdfColorTitle: '#000',
  pdfColorTableHeader: '#000285',
  pdfColorTotalInvestment: '#88c6ed',
  pdfColorTotalInvestmentWithInflation: '#394ba0',
  pdfColorRent: '#82c341',
  pdfColorRentWithInflation: '#008a70',
  pdfColorPayment: '#faa31b',
  pdfColorPaymentWithInflation: '#ef4444',
  pdfColorLine: '#c2c2c2',
  pdfColorDescriptionAxis: '#c2c2c2',
  pdfColorAxis: '#000000',
  pdfColorAxisAmount:  '#525659',
  pdfColorNameModelation: '#000285',
  logoPdf: 'https://dar.vatta.cz/static/logo_full_color.png',
  logoPdfWidth: '105',
  logoPdfHeight: '35',
  logoPdfAligment: 'right',
  headerBackgroundPdf: '/assets/header_pdf_white.png',

  // login page
  imageLogoLoginPage: 'https://dar.vatta.cz/static/logo_full_color.png',
  urlLogo: 'https://dar.vatta.cz/static/logo_full_white.png',
};
