
import { UserIdleModule } from 'angular-user-idle';
import { HttpClient } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { ProfileComponent } from './profile/profile.component';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-tooltip';
import {NgxWebstorageModule} from 'ngx-webstorage';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask/src/currency-mask.config';
import { ChartsModule } from 'ng2-charts';
import { IntlModule } from 'ng2-intl';
import { CustomFormsModule } from 'ng2-validation';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import { DatePipe } from '@angular/common';
//import { PdfmakeModule } from 'ng-pdf-make';
import { PdfmakeService } from 'ng-pdf-make/pdfmake/pdfmake.service';
import { PdfService } from './pdf/pdf.services';
import { NvD3Module } from 'ng2-nvd3';
import { OAuthModule } from 'angular-oauth2-oidc';
import { HttpClientModule } from '@angular/common/http';
import {SessionStorageService} from 'ngx-webstorage';
import {HttpApiInterceptor} from './auth/http-api.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { TabsModule } from 'ngx-bootstrap/tabs';
import { AppRoutingModule } from './app-routing.module';


// d3 and nvd3 should be included somewhere
import 'd3';
import 'nvd3';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatStepperModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTooltipModule,
  MatTableModule,

} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataInvestmentComponent } from './data-investment/data-investment.component';
import { GraphInvestmentComponent } from './graph-investment/graph-investment.component';
import { AmountPipe } from './pipes/amount.pipe';
import { SpaceHtmlPipe } from './pipes/space.pipe';
import { RoundPipe } from './pipes/round.pipe';
import { ChargeComponent } from './charge/charge.component';
import { CurrencyPipe } from './pipes/currency.pipe';
import { RentComponent } from './rent/rent.component';
import { DataRentComponent } from './data-rent/data-rent.component';

import { DialogChargeSale } from './charge/charge.component';
import { DialogCheckColumns } from './profile/profile.component';
import { LoginComponent } from './login/login.component';
import { AngularWebStorageModule } from 'angular-web-storage';
import { LogoutComponent } from './logout/logout.component';
import { ErrorComponent } from './error/error.component';

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    DataInvestmentComponent,
    GraphInvestmentComponent,
    AmountPipe,
    ChargeComponent,
    RoundPipe,
    SpaceHtmlPipe,
    CurrencyPipe,
    RentComponent,
    DialogCheckColumns,
    DialogChargeSale,
    DataRentComponent,
    LoginComponent,
    LogoutComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule, FormsModule, MatButtonModule, MatInputModule, TooltipModule, NgbModule,
    MatDialogModule, MatSelectModule,  CurrencyMaskModule, MatExpansionModule, MatTableModule, ChartsModule, CustomFormsModule,
     MatStepperModule,   MatCheckboxModule, MatAutocompleteModule,  MatInputModule, FormsModule, ReactiveFormsModule,   MatCheckboxModule,
     BrowserAnimationsModule,  MatIconModule, MatSlideToggleModule, NvD3Module, MatNativeDateModule, MatDatepickerModule, MatChipsModule,
     MatProgressSpinnerModule, MatProgressBarModule,  HttpClientModule, MatDialogModule, MatTooltipModule, AppRoutingModule,   AngularWebStorageModule,
     TabsModule.forRoot(), OAuthModule.forRoot(), NgxWebstorageModule.forRoot(), UserIdleModule.forRoot({idle: 1200, timeout: 3, ping: 12})

  ],
  providers: [PdfmakeService, PdfService, { provide: HTTP_INTERCEPTORS, useClass: HttpApiInterceptor, multi: true } ],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogCheckColumns, DialogChargeSale
  ],
})
export class AppModule {



}

