export class IntervalSale {
  // začátek intervalu slevy
  percent: number;
  // začátek intervalu slevy
  begin: number;
  // konec intervalu slevy
  end: number;

  constructor( intervalBegin: number, intervalEnd: number, percentSale: number) {
    this.begin = intervalBegin;
    this.end = intervalEnd;
    this.percent = percentSale;
  }
}
