import { Rent } from './rent';
import { SingleInvestment } from './singleInvestment';
import { RegurarlyInvestment } from './regurarlyInvestment';

export enum currency {
  'CZK' = 1,
  'EUR' = 2,
  'USD' = 3
}

export enum currencyCharts {
  'Kč' = 1,
  '€' = 2,
  '$' = 3
}






export class Profile {
  amount_max: number;
  amount_max_rent: number;
  statusMakePdf: boolean;
  createDate: Date;
  // Název modelace
  nameModeling: string;
  // měna vkladů
  currency: currency;
  // Investiční horizont (roky)
  horizon: number;
  // Předpokládané brutto zhodnocení (% p.a.)
  assumptionEvaluation: number;
  // Předpokládaná inflace
  assumptionInflation: number;
  // Zhodnocení se započtením inflace
  evaluationWithInflation: number;
  // Renta
  rent: Rent;

  valid: boolean;

  singleInvestments: SingleInvestment[] = [];

  regurarlyInvestments: RegurarlyInvestment[] = [];

  constructor() {
    this.valid = true;
    this.currency = 1;
    this.horizon = 360;
    // Perioda pravidelne investice
    // Předpokládané brutto zhodnocení (% p.a.)
    this.assumptionEvaluation = 6;
    // Předpokládaná inflace
    this.assumptionInflation = 2.5;
    // Zhodnocení se započtením inflace
    this.evaluationWithInflation = 3.41;
    this.createDate = new Date();
    this.rent = new Rent();
  }


  setValid(valid: boolean){
    this.valid = valid;
  }



  setEvaluationWithInflation() {
    // tslint:disable-next-line:max-line-length
    this.evaluationWithInflation = (((this.assumptionEvaluation / 100) - (this.assumptionInflation / 100)) / (1 + (this.assumptionInflation / 100))) * 100;
  }



  isValidInvestmentsAll(): boolean {
    if ( (this.regurarlyInvestments.length === 0 ) && (this.singleInvestments.length === 0 )){
      return false;
    }

    for (let index = 0; index < this.regurarlyInvestments.length; index++) {
      if ( !this.regurarlyInvestments[index].valid) {
        return false;
      }
    }
    for (let index = 0; index < this.singleInvestments.length; index++) {
      if ( !this.singleInvestments[index].valid) {
        return false;
      }
    }
    return true;
}


  generateLabelTime(horizon: number): string {

    if (isNaN(horizon) || horizon === 0) {
      return ' ';
    }
    const mounth = horizon % 12;
    const year = ((horizon - mounth) / 12);
    let text_mount = '';
    let text_year = '';
    let and = ' ';
    if (year === 0 || mounth === 0) {
      and = '';
    }

    if (mounth === 0) {
      text_mount = '';
    } else if (mounth < 2) {
      text_mount = mounth + '&nbsp;měsíc';
    } else if (mounth < 5) {
      text_mount = mounth + '&nbsp;měsíce';
    } else {
      text_mount = mounth + '&nbsp;měsíců';
    }
    if (year === 0) {
      text_year = '';
    } else if (year < 2) {
      text_year = year + '&nbsp;rok';
    } else if (year < 5) {
      text_year = year + '&nbsp;roky';
    } else {
      text_year = year + '&nbsp;roků';
    }
    // tslint:disable-next-line:no-unused-expression
    return text_year + and + text_mount;

  }



  generateLabelTimeNoSpace(horizon: number): string {

    if (isNaN(horizon) || horizon === 0) {
      return ' ';
    }
    const mounth = horizon % 12;
    const year = ((horizon - mounth) / 12);
    let text_mount = '';
    let text_year = '';
    let and = ' ';
    if (year === 0 || mounth === 0) {
      and = '';
    }

    if (mounth === 0) {
      text_mount = '';
    } else if (mounth < 2) {
      text_mount = mounth + ' měsíc';
    } else if (mounth < 5) {
      text_mount = mounth + ' měsíce';
    } else {
      text_mount = mounth + ' měsíců';
    }
    if (year === 0) {
      text_year = '';
    } else if (year < 2) {
      text_year = year + ' rok';
    } else if (year < 5) {
      text_year = year + ' roky';
    } else {
      text_year = year + ' roků';
    }
    // tslint:disable-next-line:no-unused-expression
    return text_year + and + text_mount;

  }




}













