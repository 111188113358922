import { AuthConfig } from 'angular-oauth2-oidc';

import { environment } from '../../environments/environment';



export const authConfig: AuthConfig = {
    issuer: environment.issuer,
    loginUrl: environment.loginUrl,
    redirectUri :  environment.redirectUri,
    clientId: environment.clientId,
    scope: 'read write',
    oidc: false,
    responseType: 'token',
    showDebugInformation: true,
    requestAccessToken: true,
  };



