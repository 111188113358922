import { IntervalSale } from './intervalSale';
export class Sale {
  // zahrnout jednorázové netto vklady do výpočtu
  calculateNettoInvestment: boolean;
  minInterval: number;
  interval: IntervalSale[] = [];
  // na poplatek z plateb jde v procentech
  percentCharge: number;
  constructor() {
  this.calculateNettoInvestment = false;
  this.percentCharge = 60;
  this.minInterval = 1000000;
  this.interval[0] = new IntervalSale(0, 349999, 5);
  this.interval[1] = new IntervalSale(350000, 999999, 4.1);
  this.interval[2] = new IntervalSale(1000000, 1000000000, 3.1);
  }

}






