
import { DataInvestmentSingle } from './dataInvestmentSingle';

import { calculationChargeEnum } from './calculationChargeEnum';


export class SingleInvestment {
  charge: number;

  // stav validace
  valid: boolean;

  // číslo platby
  number_charge: number;

  // Netto jednorázová investice
  nettoSingleInvestment: number;

  // Zhodnocení se započtením inflace
  evaluationWithInflation: number;

  // Jednorázová brutto investice
  singleBruttoInvestment: number;

  // Sazba vstupního poplatku jednorázové investice
  inputChargeSingleInvestment: number;

  // způsob strhávání poplatku
  chargeModeSingle: calculationChargeEnum;

  // tabulka investovaných částek
  dataInvestment: DataInvestmentSingle[] = [];


  constructor() {
    this.charge = 0;
    this.number_charge = 1;
    this.nettoSingleInvestment = 0;
    this.evaluationWithInflation = 0;
    this.singleBruttoInvestment = undefined;
    this.inputChargeSingleInvestment = 0;
    this.chargeModeSingle = 2;
    this.valid = false;
  }

  /*
    * @function
    *
    * @description
    * Nastaví počet řádků podle doby pravydelné investice
    *
    */
   setHorizon(horizon: number){
    this.dataInvestment.splice(0, this.dataInvestment.length + 1);
    for( let i = 0; i < horizon; i++){
      this.dataInvestment.push(new DataInvestmentSingle());
    }
  }

  setValidation(valid: boolean) {
    this.valid = valid;
  }

  setDeposit() {
    if ((this.number_charge + 1 ) > this.dataInvestment.length ){
      return;
    }
    this.dataInvestment[this.number_charge - 1].deposit = this.singleBruttoInvestment;
  }


  setChargeSingle() {
    // tslint:disable-next-line:max-line-length
    if(this.dataInvestment.length < this.number_charge){
      return;
    }
    this.charge = this.calculateCharge(this.singleBruttoInvestment, this.inputChargeSingleInvestment, this.chargeModeSingle);
    this.dataInvestment[this.number_charge - 1].charge = this.charge;
    this.nettoSingleInvestment = this.singleBruttoInvestment - this.charge;
  }

  /*
    * @requires $investment - částka která ude investována pravidelně
    * @requires $rateInvestment - zhodnocení částky
    * @requires $modeCharge - typ placení poplatku
    * @function
    *
    * @description
    * Vrátí hodnotu poplatku
    *
    */
   calculateCharge(investment: number, rateInvestment: number, modeCharge: number): number {
    if (isNaN(rateInvestment) || (rateInvestment === 0) || isNaN(investment) || (investment === 0)) {
      return 0;
    }
    switch (modeCharge) {
      case 1:
        return ((investment * (rateInvestment / 100)));
      case 2:
        return ((investment * (rateInvestment / 100)) / (1 + (rateInvestment / 100)));
    }
  }








}
