import { CheckColumns } from './checkColumns';
import { CheckChart } from './checkChart';

export class CheckExport {

checkColumns: CheckColumns;
checkChart: CheckChart;

constructor(){
  this.checkColumns = new CheckColumns();
  this.checkChart = new CheckChart();
}



}
