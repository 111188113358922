
export class DataInvestmentSingle {

  // vklad pravidelné investice
  deposit: number;

  // celkově investováno
  totalInvestment: number;

  // celkově investováno s inflací
  totalInvestmentInflation: number;

  // poplatek
  charge: number;

  constructor() {
    this.deposit = 0;
    this.totalInvestment = 0;
    this.totalInvestmentInflation = 0;
    this.charge = 0;
  }
}
