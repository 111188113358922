import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtrRound'
})
export class RoundPipe implements PipeTransform {
  transform(value: number): number {
    if( isNaN(value) ){
      return;
    }
    //return Math.round(value * 100 ) / 100;
    return Math.round(value);
  }
}
