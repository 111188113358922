
export class CheckChart {

constructor(){
  this.chartRent = true;
  this.chartInvestment = true;
  this.chartAll = true;
}


  // graf renty
  chartRent: boolean;

  // graf vkladů
  chartInvestment: boolean;

  // graf vkladů a renty
  chartAll: boolean;

}
