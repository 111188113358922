
import { periodeEnum } from './periodeEnum';
import { relativeTimeRounding } from 'moment';
import { Profile } from './profile';
export class Rent {
  // kalkulovat rentu
  on: boolean;
  // Brutto zhodnocení fáze renty
  evaluationBrutto: number;
  // Perioda čerpání renty
  periode: periodeEnum;
  // typ výpočtu renty
  typeCalculation: rentTypeCalculation;
  // Požadovaná minimální renta
  minRent: number;
  //Počet měsíců čerpání renty
  horizon: number;
  //Počet vyplacených rent
  countRent: number;
  // Požadovaná minimální renta pro výpočet
  rentCalculateHorizon: number;
    // typ výpočtu renty
  base: rentBase;

  valid: boolean;

  lastFullRent: boolean;
  // maximálmí renta 100 let. Bere se jako nekonečná renta.
  maxCount: number;


  constructor() {
    this.on = false;
    this.evaluationBrutto = 6;
    this.periode = 12;
    this.typeCalculation = 2;
    this.base = 1;
    this.valid = false;
    this.maxCount = 1200;
  }

init() {
  this.horizon = undefined;
  this.minRent = undefined;
  this.periode = 12;
  this.countRent = undefined;
  this.valid = false;
  this.rentCalculateHorizon = undefined;
}




switchType() {
    switch (this.typeCalculation) {
      case 1:
      this.typeCalculation = 2;
      return;
      case 2:
      this.typeCalculation = 1;
      return;
      case 3:
      this.typeCalculation = 4;
      return;
      case 4:
      this.typeCalculation = 3;
      return;
    }

}


setValid( valid: boolean) {
  this.valid = valid;
}


}


export enum rentBase {
  'Reálná' = 1,
  'Nominální' = 2,
}


export enum rentTypeCalculation {
  'Renta z úspor v nominální výši na dopočtenou dobu' = 1,
  'Renta z úspor v reálné výši na dopočtenou dobu' = 2,
  'Renta z úspor na zadanou dobu v dopočtené nominální výši' = 3,
  'Renta z úspor na zadanou dobu v dopočtené reálné výši' = 4
}






