import {Injectable} from '@angular/core';
import {ParseUrlService} from './parse-url.service';
import {AuthStorageService} from './auth-storage.service';
import { UserService} from '../user/user.service';
import { User } from '../user/user';
import {Router} from '@angular/router';

import {ErrorComponent} from '../error/error.component';
import {  throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

import {throwError as observableThrowError,  Observable } from 'rxjs';

import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { ThrowStmt } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private parseUrlService: ParseUrlService, private authStorage: AuthStorageService, private _http: HttpClient, private userService: UserService, private http: HttpClient, private router: Router) {
  }
    user: User;

  chceckAccessToken(): boolean {
    let params = this.parseUrlService.getParamsObjectFromHash();
    if (params && params['access_token'] ) {
      this.authStorage.save(params['access_token']);
      this.userService.getUserInfo(this.authStorage.getToken()).then(result => {  this.user = result; console.log(this.user);
      }).catch(err => {   this.checkStatus(  err.statusText , err.status); return err.ok; }  );
    }

    return true;
  }


  checkStatus(  title: string, status_code: string ) {
   ErrorComponent.status = status_code;
   ErrorComponent.message = title;
  this.logout();
  this.router.navigate(['/error']);

  }

  revokeToken(): Observable<any> {

    const httpOptionsRevoke = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })};
      return this.http.post<any>(environment.revokeToken, 'token=' + this.authStorage.getToken() + '&client_id=' + environment.clientId , httpOptionsRevoke);
   }


  haveAccessToken(): boolean {
    let at = this.authStorage.getToken();
    return at != null;
  }

  getAccessToken(): string {
    return this.authStorage.getToken();
  }

  clearAccessToken() {
    this.authStorage.clear();
  }

  logout() {
    this.authStorage.clear();
    this.router.navigate(['/logout']);
  }
}
