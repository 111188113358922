import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Profile, currency } from '../profile/profile';
import { periodeEnum } from '../profile/periodeEnum';

import { Observable, Subject } from 'rxjs';


import { debounceTime, debounce, delay, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-rent',
  templateUrl: './rent.component.html',
  styleUrls: ['./rent.component.css'],
})
export class RentComponent implements OnInit {
  @Input() public profile: Profile;
  @Input() public saved: number;
  @Input() public currency: currency;

  public keyUp = new Subject<string>();
  @Output() updateData: EventEmitter<string> = new EventEmitter();
  constructor() {
    const subscription = this.keyUp
  .pipe(
    debounceTime(1000)
  )
  .pipe(distinctUntilChanged())
  .pipe(delay(500)
  ).subscribe(() => { this.updateDataTable(); });
  }

  ngOnInit() {

  }



  getPeriodeRent(){
    switch(this.profile.rent.periode) {
      case 12: {
         return 'měsíčně';
      }
      case 2: {
         return 'pololetně';
      }
      case 4: {
        return 'čtvrtletně';
     }
     case 1: {
      return 'ročně';
     }
      default: {
         break;
      }
   }
   return '';
  }



  generateLabelTimeRent( count: number ): String {
    if(this.profile.rent.lastFullRent){
      return this.profile.generateLabelTime(count);
    } else{
      return this.profile.generateLabelTime(count - 1);
    }
  }


  getPeriode(): any {
    return (periodeEnum[this.profile.rent.periode]);
  }

  updateDataTable() {
    this.updateData.emit('updateData');
  }


  isInfiniteRent() {
    /*if(this.profile.rent.countRent !== undefined){
      return false;
    }*/
    const count =  (12 / this.profile.rent.periode) * this.profile.rent.countRent;
    if (count >= 1200) {
      return true;
    }
    else {
      return false;

    }
  }



}
