import { Component, OnInit, Input } from '@angular/core';
import { DataInvestment } from '../data-investment/dataInvestment';
import { Profile } from '../profile/profile';
import { CheckColumns } from '../profile/checkColumns';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-data-rent',
  templateUrl: './data-rent.component.html',
  styleUrls: ['./data-rent.component.css']
})
export class DataRentComponent implements OnInit {
  @Input() public profile: Profile;
  @Input() public checkColumns: CheckColumns;
  @Input() public dataInvestmentTable: DataInvestment[];
  constructor() { }

  ngOnInit() {
    //let a = document.getElementById('panel-title-tabs');
    //let height_panel = a.offsetHeight;
    //let a = document.getElementById('data-investment-table').setAttribute.;
    //let height = window.screen.height;
    //let height_data_table = height - height_panel - 25;
  }







  showYearCashout(indexYear: number): number {
    let cashoutSum = 0;
    let max_count = 12;
    if (this.checkColumns.detailHorizon || indexYear === 0) {
      return this.dataInvestmentTable[indexYear].cashout;
    } else {
      if (((this.dataInvestmentTable.length - 1 ) === indexYear )) {
        max_count = (this.dataInvestmentTable.length % 12);

      }
      for (let u = 0; max_count > u; u++) {
        if ( isNullOrUndefined(this.dataInvestmentTable[indexYear - u])) {
          return cashoutSum;
        } else if ( isNullOrUndefined(this.dataInvestmentTable[indexYear - u].cashout)) {
          cashoutSum += 0;
        } else {
        cashoutSum += this.dataInvestmentTable[indexYear - u].cashout;
        }
      }
    }
    return cashoutSum;
  }



  showYearCashoutReal(indexYear: number): number {
    let cashoutSum = 0;
    let max_count = 12;
    if (this.checkColumns.detailHorizon || indexYear === 0) {
      return this.dataInvestmentTable[indexYear].cashoutReal;
    } else {
      if (((this.dataInvestmentTable.length - 1 ) === indexYear )) {
        max_count = (this.dataInvestmentTable.length % 12);
      }
      for (let u = 0; max_count > u; u++) {
        cashoutSum += this.dataInvestmentTable[indexYear - u].cashoutReal;
      }
    }
    return cashoutSum;
  }

  checkDetailRenta(index: number): boolean {
    if (index === (this.dataInvestmentTable.length - this.profile.horizon)) {
      return true;
    }
    if (index === 0) {
      return true;
    }
    if (((index) % 12) === 0) {
      return true;
    }
    return false;
  }


/*
  checkDetail(index: number): boolean {
    //if ((index % this.profile.periode) === 0) {
    if (index === 0) {
      return false;
    }
    if (((index + 1) % 12) === 0) {
      return true;
    }
    return false;
  }*/

  showYear(index): any {

    if (this.checkColumns.detailHorizon) {
      //return String(this.currentYear) + " /  00";
      return index + 1;
    } else {
      return ((index + 1) / 12); //(12 / this.profile.periode));
    }
  }

  showYearRent(index): any {
    let index_rent = index - this.profile.horizon;
    if (this.checkColumns.detailHorizon) {
      //return String(this.currentYear) + " /  00";
      return index - this.profile.horizon + 1;
    }
    else {
        if (((index - this.profile.horizon) % 12) > 0) {
          if ((index - this.profile.horizon) < 12 ){
            return 1;
          }
          else{
          let residue = ((index - this.profile.horizon) % 12);
          return ((index - this.profile.horizon - residue) / 12) + 1;
          }
        }
        return ((index_rent) / 12) + 1; //(12 / this.profile.periode));
      }

      /*let quarter = index % this.profile.periode;
      let a = index - quarter;
      let horizont = a / this.profile.periode;*/
  }


}
