import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtrCurrency'
})
export class CurrencyPipe implements PipeTransform {
  transform(value: any, currency: any): string {
    /*if(isNaN(value) ){
      return '-';
    }*/
    switch (currency) {
      case 1:
      return value + ' Kč';
      case 2:
      return value + ' €';
      case 3:
      return value + ' $';
    }
    return value + ' - ';

  }
}
