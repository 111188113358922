  import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { Profile, currency } from '../profile/profile';

import { Observable, Subject } from 'rxjs';
import { IntervalSale } from '../profile/intervalSale';
import { RegurarlyInvestment } from '../profile/regurarlyInvestment';

import { debounceTime, debounce, delay, distinctUntilChanged } from 'rxjs/operators';


import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'app-charge',
  templateUrl: './charge.component.html',
  styleUrls: ['./charge.component.css'],
})
export class ChargeComponent implements OnInit {
  @Input() public regurarlyInvestment: RegurarlyInvestment;
  @Input() public currency: currency;
  @Output() updateData: EventEmitter<string> = new EventEmitter();
  intervalNew: IntervalSale;
  public keyUpCharge = new Subject<string>();
  constructor(public dialog: MatDialog) {

    const subscription = this.keyUpCharge
    //.map(event => event.target.value)
    //.subscribe(() => {this.recalcInvestmentAll(); });
    .pipe(
      debounceTime(1000)
    )
    .pipe(distinctUntilChanged())
    .pipe(delay(500)
    ).subscribe(() => { this.updateDataTable();});

  }

  ngOnInit() {
  }


  updateDataTable() {
    this.updateData.emit('updateData');
  }

  precisionRound(number, precision) {
    let factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  }


  createNewIntervalSale() {
    this.intervalNew = new IntervalSale(0, 0, 0);
  }

  openDialogChargeSale(): void {
    const dialogRef = this.dialog.open(DialogChargeSale, {
      data: { regurarlyInvestment: this.regurarlyInvestment, currency: this.currency }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.regurarlyInvestment.sale = dialogRef.componentInstance.data.regurarlyInvestment.sale;
    });
  }




  removeInterval() {
    this.regurarlyInvestment.sale.interval.splice(this.regurarlyInvestment.sale.interval.length - 2, 1);
    if (this.regurarlyInvestment.sale.interval.length > 1) {
      this.regurarlyInvestment.sale.interval[this.regurarlyInvestment.sale.interval.length - 1].begin = this.regurarlyInvestment.sale.interval[this.regurarlyInvestment.sale.interval.length - 2].end;
      this.regurarlyInvestment.sale.minInterval = this.regurarlyInvestment.sale.interval[this.regurarlyInvestment.sale.interval.length - 1].begin + 1;
    } else {
      this.regurarlyInvestment.sale.interval[0].begin = 0;
      this.regurarlyInvestment.sale.minInterval = 1;
    }


  }


  addChargeInterval() {
    this.regurarlyInvestment.sale.interval[this.regurarlyInvestment.sale.interval.length - 1].end = this.intervalNew.end;
    let interval = new IntervalSale(this.intervalNew.end + 1, 1000000000, this.regurarlyInvestment.sale.interval[this.regurarlyInvestment.sale.interval.length - 1].percent);
    this.regurarlyInvestment.sale.interval[this.regurarlyInvestment.sale.interval.length - 1].percent = this.intervalNew.percent;
    this.regurarlyInvestment.sale.interval.push(interval);
    this.intervalNew = undefined;
    if (this.regurarlyInvestment.sale.interval.length > 1) {
      this.regurarlyInvestment.sale.minInterval = this.regurarlyInvestment.sale.interval[this.regurarlyInvestment.sale.interval.length - 2].end + 1;
    }
    else{
      this.regurarlyInvestment.sale.minInterval = 1;
    }
  }

}


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-charge-sale',
  templateUrl: './chargeSaleDialog.component.html',
 // styleUrls: ['../app.component.css']
})

export class DialogChargeSale{
   regurarlyInvestment: RegurarlyInvestment;
   intervalNew: IntervalSale;
   currency: number;
   infinity: string;
  constructor(
    public dialogRef: MatDialogRef<DialogChargeSale>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      //console.log(this.data.regurarlyInvestment);
      this.regurarlyInvestment = this.data.regurarlyInvestment;
      this.currency = this.data.currency;
      this.infinity = '∞';
    }
  onNoClick(): void {
    this.dialogRef.close('zaviram');
  }
  createNewIntervalSale() {
    this.intervalNew = new IntervalSale(0, 0, 0);
  }

  addChargeInterval() {
    this.regurarlyInvestment.sale.interval[this.regurarlyInvestment.sale.interval.length - 1].end = this.intervalNew.end;
    let interval = new IntervalSale(this.intervalNew.end + 1, 1000000000, this.regurarlyInvestment.sale.interval[this.regurarlyInvestment.sale.interval.length - 1].percent);
    this.regurarlyInvestment.sale.interval[this.regurarlyInvestment.sale.interval.length - 1].percent = this.intervalNew.percent;
    this.regurarlyInvestment.sale.interval.push(interval);
    this.intervalNew = undefined;
    if (this.regurarlyInvestment.sale.interval.length > 1) {
      this.regurarlyInvestment.sale.minInterval = this.regurarlyInvestment.sale.interval[this.regurarlyInvestment.sale.interval.length - 2].end + 1;
    }
    else {
      this.regurarlyInvestment.sale.minInterval = 1;
    }
  }
  removeInterval() {
    this.regurarlyInvestment.sale.interval.splice(this.regurarlyInvestment.sale.interval.length - 2, 1);
    if (this.regurarlyInvestment.sale.interval.length > 1) {
      this.regurarlyInvestment.sale.interval[this.regurarlyInvestment.sale.interval.length - 1].begin = this.regurarlyInvestment.sale.interval[this.regurarlyInvestment.sale.interval.length - 2].end;
      this.regurarlyInvestment.sale.minInterval = this.regurarlyInvestment.sale.interval[this.regurarlyInvestment.sale.interval.length - 1].begin + 1;
    } else {
      this.regurarlyInvestment.sale.interval[0].begin = 0;
      this.regurarlyInvestment.sale.minInterval = 1;
    }


  }

}
