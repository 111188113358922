import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from './auth.service';
import {ErrorComponent} from '../error/error.component';


@Injectable({
  providedIn: 'root'
})
export class LoggedUserGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if(this.authService.haveAccessToken()) {
      return true;
    }
    /*if (this.oauthService.hasValidAccessToken()) {
      return true;
    }*/
    ErrorComponent.status = '403';
    ErrorComponent.message  = 'Forbiden';
    this.router.navigate(['error']).then();
    return false;
  }
}
