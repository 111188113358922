import { Component, OnInit } from '@angular/core';
import { ChartsModule } from 'ng2-charts';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { OAuthService, JwksValidationHandler, AuthConfig, NullValidationHandler  } from 'angular-oauth2-oidc';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { authConfig } from './auth/auth.config';
import { RouterModule, Routes } from '@angular/router';
import {NgxWebstorageModule} from 'ngx-webstorage';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { OAuthErrorEvent } from 'angular-oauth2-oidc';
import {AuthService} from './auth/auth.service';
import {ErrorComponent} from './error/error.component';

import { environment } from './../environments/environment';

import {Router} from '@angular/router';



import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask/src/currency-mask.config';
export let CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'center',
  allowNegative: true,
  decimal: ',',
  precision: 0,
  prefix: '',
  suffix: '  Kč  ',
  thousands: ' ',
};


import 'hammerjs';

import {
  Input
} from '@angular/core';

import { ProfileComponent } from './profile/profile.component';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    { provide: MAT_DATE_LOCALE, useValue: 'cs-CS' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }]
})
export class AppComponent {
  title = 'app';
  login: boolean;



  constructor(private oauthService: OAuthService,  private authService: AuthService, private router: Router) {

    oauthService.oidc = false;
    this.configureWithNewConfigApi();
  }


  private configureWithNewConfigApi() {

    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
    this.oauthService.events.subscribe(e => {
      if (e instanceof OAuthErrorEvent) { console.error(e);
      } else { console.warn(e); }
    });

    if ( !this.authService.chceckAccessToken() ) {
      ErrorComponent.status = '403';
      this.authService.clearAccessToken();
      this.router.navigate(['/error']);
    }
  }

}
