import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  brandLargeImg = environment.imageLogoLoginPage;
  constructor(private oauthService: OAuthService, private authService: AuthService) {

    this.authService.revokeToken().subscribe(response => {
      console.log(response); this.oauthService.logOut();
      this.authService.logout();
      });
    }

    public login() {
      this.oauthService.initImplicitFlow();
  }

  ngOnInit() {
  }

}
