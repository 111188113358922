import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DataInvestment } from '../data-investment/dataInvestment';
import { Profile } from '../profile/profile';
import { isNullOrUndefined } from 'util';
// d3 and nvd3 should be included somewhere
import * as d3 from 'd3';
import 'nvd3';
import { NvD3Module } from 'ng2-nvd3';
@Component({
  selector: 'app-graph-investment',
  templateUrl: './graph-investment.component.html',
  //styleUrls: ['./graph-investment.component.css'],
  styleUrls: ['./nv.d3.css'],
})
export class GraphInvestmentComponent implements OnInit {
  @Input() public dataInvestment: Array<DataInvestment>;
  @Input() public profile: Profile;
  public totalInvestmentChart: Array<any> = [];
  public paymentChart: Array<any> = [];
  public paymentWithInflationChart: Array<any> = [];
  public RentChart: Array<any> = [];
  public RentInflationChart: Array<any> = [];
  public totalInvestmentWithInflationChart: Array<any> = [];
  @Input() public dataHorizontGraph: Array<any>;
  @Input() public enabledGraph: boolean;
  @ViewChild('nvd3') nvd3;
  public evolutionReal: number;
  public evolutionNominal: number;
  public countRenta: number;
  public show_chart: number;
  public lineChartOptions: any = {
    responsive: true
  };
  options;
  d3: any;
  data;
  url: string ;
  svgAsPngUri: any;
  calculateEvalution(depositAll: number, totalInvestment: number) {
    return Math.round((totalInvestment / ( depositAll / 100 )));
  }


  checkChart() {
    if ( ( DataInvestment.length === (  this.profile.horizon + this.profile.rent.horizon ) ) && ( this.profile.rent.on)) {
      return true;
    } else if ( (DataInvestment.length ===   this.profile.horizon  ) && (!this.profile.rent.on) ) {
      return true;
    } else {
      return false;
    }
  }




  updateChart() {
    this.countRenta = 0;
    this.evolutionNominal = this.calculateEvalution(this.dataInvestment[this.profile.horizon - 1].payment, this.dataInvestment[this.profile.horizon - 1].totalInvestment );
    this.evolutionReal = this.calculateEvalution(this.dataInvestment[this.profile.horizon - 1].payment, this.dataInvestment[this.profile.horizon - 1].totalInvestmentWithInflation );
    this.totalInvestmentChart.splice(0, this.totalInvestmentChart.length + 1);
    this.paymentChart.splice(0, this.paymentChart.length + 1);
    this.paymentWithInflationChart.splice(0, this.paymentWithInflationChart.length + 1);
    this.RentInflationChart.splice(0, this.RentInflationChart.length + 1);
    this.RentChart.splice(0, this.RentChart.length + 1);
    this.totalInvestmentWithInflationChart.splice(0, (Math.round(this.totalInvestmentWithInflationChart.length + 1)));
    let i = 0;
    this.paymentChart.push({ x: 0, y: (Math.round(this.dataInvestment[0].payment)) });
    this.paymentWithInflationChart.push({ x: 0, y: (Math.round(this.dataInvestment[0].payment)) });
    this.totalInvestmentChart.push({ x: 0, y: (Math.round(this.dataInvestment[0].payment)) });
    this.totalInvestmentWithInflationChart.push({ x: 0, y: (Math.round(this.dataInvestment[0].payment)) });
    for (let entry of this.dataInvestment) {
      if (this.profile.rent.on  || (this.dataInvestment.length > this.profile.horizon )  ) {
        if (i < this.profile.horizon) {
          this.totalInvestmentChart.push({ x: i + 1, y: (Math.round(entry.totalInvestment)) });
          this.totalInvestmentWithInflationChart.push({ x: i + 1, y: (Math.round(entry.totalInvestmentWithInflation)) });
          this.profile.amount_max =  entry.totalInvestment;
          this.profile.amount_max_rent =  entry.totalInvestment;
        } else {
          if (i === this.profile.horizon) {
          this.RentChart.push({ x: i, y: (Math.round(this.dataInvestment[i - 1].totalInvestment)) });
          this.RentInflationChart.push({ x: i, y: (Math.round(this.dataInvestment[i - 1].totalInvestmentWithInflation)) });
          this.RentChart.push({ x: (i + 1), y: (Math.round(this.dataInvestment[i].totalInvestment +  entry.cashout)) });
           this.RentChart.push({ x: (i + 1), y: (Math.round(this.dataInvestment[i].totalInvestment)) });
          this.RentInflationChart.push({ x: (i + 1), y: (Math.round(this.dataInvestment[i - 1].totalInvestmentWithInflation)) });
          this.RentInflationChart.push({ x: (i + 1), y: (Math.round(entry.totalInvestmentWithInflation)) });
          } else {
           /* if (this.profile.horizon === i) {
            this.RentChart.push({ x: i, y: (Math.round(this.dataInvestment[i - 1].totalInvestment - entry.cashout)) });
            this.RentInflationChart.push({ x: i, y: (Math.round(this.dataInvestment[i].totalInvestmentWithInflation)) });
            } else*/ if (entry.cashout > 0) {
              this.RentChart.push({ x: (i + 1), y: (Math.round(entry.totalInvestment + entry.cashout)) });
              this.RentChart.push({ x: (i + 1), y: (Math.round(entry.totalInvestment)) });
              this.RentInflationChart.push({ x: (i + 1), y: (Math.round(this.dataInvestment[i - 1].totalInvestmentWithInflation)) });
              this.RentInflationChart.push({ x: (i + 1), y: (Math.round(entry.totalInvestmentWithInflation)) });
            } else {
              this.RentInflationChart.push({ x: i  , y: (Math.round(entry.totalInvestmentWithInflation)) });
            }

          }
          }
          if( this.RentChart.length > 0){
            if ( this.RentChart[ this.RentChart.length  - 1 ].y  > this.profile.amount_max_rent ) {
              this.profile.amount_max_rent = this.RentChart[ this.RentChart.length - 1 ].y;
             }
          }

        } else {
        if (i <= this.profile.horizon) {
          this.totalInvestmentChart.push({ x: i + 1, y: (Math.round(entry.totalInvestment)) });
          this.totalInvestmentWithInflationChart.push({ x: i + 1, y: (Math.round(entry.totalInvestmentWithInflation)) });
          this.profile.amount_max =  entry.totalInvestment;
        }
      }
      if ( this.paymentChart[ this.paymentChart.length  - 1 ].y  > this.profile.amount_max_rent ) {
          this.profile.amount_max_rent = this.paymentChart[ this.paymentChart.length - 1 ].y;
       }
       if ( this.paymentChart[ this.paymentChart.length  - 1 ].y  > this.profile.amount_max ) {
        this.profile.amount_max = this.paymentChart[ this.paymentChart.length - 1 ].y;
      }
      this.paymentChart.push({ x: i + 1, y: (Math.round(entry.payment)) });
      this.paymentWithInflationChart.push({ x: i + 1, y: (Math.round(entry.payementInflation)) });
      i++;
    }

    this.updateData();
      this.nvd3.chart.update();

  }

  updateData() {
    let y_max_chart = this.profile.amount_max ;
    if ( this.show_chart === undefined ) {
       this.show_chart  = 1;
    }
    if ( this.show_chart > 1) {
      y_max_chart  = this.profile.amount_max_rent;
    }

    if( isNullOrUndefined(this.options)) {
    this.optionsInit();
    }



    this.options.chart.yDomain = [0, Math.round(y_max_chart  + (y_max_chart  / 20  ))];
    switch (this.show_chart) {
      case 1: {
        this.data = [
          {
            values: this.paymentChart.slice( 0, this.profile.horizon + 1),      //values - represents the array of {x,y} data points
            //      values: this.sinAndCos(),      //values - represents the array of {x,y} data points
            key: 'Historicky vloženo', //key  - the name of the series.
            color: '#faa31b'  //color - optional: choose your own line color.
          },
          {
            values: this.paymentWithInflationChart.slice( 0, this.profile.horizon + 1),      //values - represents the array of {x,y} data points
            //      values: this.sinAndCos(),      //values - represents the array of {x,y} data points
            key: 'Historicky vloženo s inflací', //key  - the name of the series.
            color: '#ef4444'  //color - optional: choose your own line color.
          },
          {
            values: this.totalInvestmentChart.slice( 0, this.profile.horizon + 1),      //values - represents the array of {x,y} data points
            //      values: this.sinAndCos(),      //values - represents the array of {x,y} data points
            key: 'Průběh celkem', //key  - the name of the series.
            color: '#88c6ed' //color - optional: choose your own line color.
          },
          {
            values: this.totalInvestmentWithInflationChart.slice( 0, this.profile.horizon + 1)  ,      //values - represents the array of {x,y} data points
            //      values: this.sinAndCos(),      //values - represents the array of {x,y} data points
            key: 'Průběh celkem s inflací', //key  - the name of the series.
            color: '#394ba0'  //color - optional: choose your own line color.
          }
        ];
         break;
      }
      case 2: {
        this.data = [
          {
            values: this.RentChart,      //values - represents the array of {x,y} data points
            //      values: this.sinAndCos(),      //values - represents the array of {x,y} data points
            key: 'Renta', //key  - the name of the series.
            color: '#82c341'  //color - optional: choose your own line color.
          },
          {
            values: this.RentInflationChart,      //values - represents the array of {x,y} data points
            //      values: this.sinAndCos(),      //values - represents the array of {x,y} data points
            key: 'Renta s inflací', //key  - the name of the series.
            color: '#009f75'  //color - optional: choose your own line color.
          }
        ];
         break;
      }
      case 3: {
        this.data = [
          {
            values: this.paymentChart.slice( 0, this.profile.horizon +  this.profile.rent.horizon),      //values - represents the array of {x,y} data points
            //      values: this.sinAndCos(),      //values - represents the array of {x,y} data points
            key: 'Historicky vloženo', //key  - the name of the series.
            color: '#faa31b'  //color - optional: choose your own line color.
          },
          {
            values: this.paymentWithInflationChart.slice( 0, this.profile.horizon +  this.profile.rent.horizon),       //values - represents the array of {x,y} data points
            //      values: this.sinAndCos(),      //values - represents the array of {x,y} data points
            key: 'Historicky vloženo s inflací', //key  - the name of the series.
            color: '#ef4444'   //color - optional: choose your own line color.
          },
          {
            values: this.totalInvestmentChart,      //values - represents the array of {x,y} data points
            //      values: this.sinAndCos(),      //values - represents the array of {x,y} data points
            key: 'Průběh celkem', //key  - the name of the series.
            color: '#88c6ed' //color - optional: choose your own line color.
          },
          {
            values: this.totalInvestmentWithInflationChart,      //values - represents the array of {x,y} data points
            //      values: this.sinAndCos(),      //values - represents the array of {x,y} data points
            key: 'Průběh celkem s inflací', //key  - the name of the series.
            color: '#394ba0'  //color - optional: choose your own line color.
          },
          {
            values: this.RentChart,      //values - represents the array of {x,y} data points
            //      values: this.sinAndCos(),      //values - represents the array of {x,y} data points
            key: 'Renta', //key  - the name of the series.
            color: '#82c341' //color - optional: choose your own line color.
          },
          {
            values: this.RentInflationChart,      //values - represents the array of {x,y} data points
            //      values: this.sinAndCos(),      //values - represents the array of {x,y} data points
            key: 'Renta s inflací', //key  - the name of the series.
            color: '#008a70' //color - optional: choose your own line color.
          }
        ];
         break;
      }
   }
  }

 showChartPanel() {
  if (( this.profile.valid  && this.profile.isValidInvestmentsAll()) && ( (this.profile.singleInvestments.length > 0) || (this.profile.regurarlyInvestments.length > 0))) {
    return true;
  } else{
    return false;
  }
 }



  optionsInit(){
 // let domain = [0, -1  ];
 let height_data_table = window.screen.height  - 360;
 const panelOptions = document.getElementById('dashboard-chart');
 let height_panel_options  = 0;
 if(isNullOrUndefined(panelOptions)){
  height_panel_options  = 160;
} else{
 //const dashBoardChart = document.getElementById('dashboard-chart');
 //if ( !isNullOrUndefined(panelOptions)) {
 height_panel_options = panelOptions.offsetHeight;
 }
 const padding_chart = (window.screen.width * 0.09);
const height_panel_chart = 155;
 const height_main_panel = 66;
 const heigth_tasb = 51;
 height_data_table = window.screen.height - height_main_panel - height_panel_options - height_panel_chart - heigth_tasb - 100 ;


 this.options = {
   chart: {
     type: 'lineChart',
     height: height_data_table,
     //duration: 12,
     refreshDataOnly: false,
     legendPosition: 'bottom',
     //interactiveUpdateDelay: 500300,
     //yDomain: domain,
     lines: {
     },
     padding: {
       top: 220,
       right: 75,
       bottom: 40,
       left: 155
     },
     margin: {
       top: 20,
       right: padding_chart,
       bottom: 40,
       left: padding_chart
     },
     x: function (d) { return d.x; },
     y: function (d) { return d.y; },
     useInteractiveGuideline: true,
     labelSunbeamLayout: true,
     noData: '',
     useNiceScale: true,
     legend: {
       maxKeyLength: 50,
       vers: 'classic',
       padding: 55,
       rightAlign: true,
       margin: {
         top: 0,
         right: -25,
         bottom: 10,
         left: 10
         },
      // key: function(d) { return (d.key + 'test'); }
     },
     xAxis: {
       axisLabel: ' Měsíce ',
       showMaxMin: false,
     },
     yAxis: {
       showMaxMin: false,
       axisLabel: ' Kč ',
       rotateYLabel: false,
       tickPadding: 10,
       width: 115,
     /*  margin: {
         top: 100,
         right: 20,
         bottom: 40,
         left: 125
       },*/
       tickFormat: function (d) {
         return d3.format(",.3d")(d).replace(/,/g, ' ');
         //return d3.format(",.3d")(d) + "  Kč";
       },
     }

     /*zoom: {
       //NOTE: All attributes below are optional
       enabled: true,
       scale: 1,
       scaleExtent: [1, 10],
       translate: [0, 0],
       useFixedDomain: false,
       useNiceScale: false,
       horizontalOff: false,
       verticalOff: false,
       zoomed: function(xDomain, yDomain) {
           let domains = {x1: 0, x2: 0, y1: 1, y2: 1};
           return domains;
       },
       unzoomed: function(xDomain, yDomain) {
           let domains = {x1: 0, x2: 0, y1: 0, y2: 0};
           return domains;
       },
       unzoomEventType: 'dblclick.zoom'
     }*/
   },
   /*title: {
     enable: true,
     text: 'Real-time Attacks frequency',
     css: {
         'text-align': 'center',
         'color': 'black'
     }
   }*/
  /* caption: {
     enable: true,
     text: "Figure 1. Write Your Caption text.",
     css: {
       width: "nullpx",
       textAlign: "center",
       text-align: "justify",
       margin: "10px 13px 0px 7px"
     },
     html: "<b>Figure 1.</b> Lorem ipsum dolor sit amet, at eam blandit sadipscing, <span style=\"text-decoration: underline;\">vim adhuc sanctus disputando ex</span>, cu usu affert alienum urbanitas. <i>Cum in purto erat, mea ne nominavi persecuti reformidans.</i> Docendi blandit abhorreant ea has, minim tantas alterum pro eu. <span style=\"color: darkred;\">Exerci graeci ad vix, elit tacimates ea duo</span>. Id mel eruditi fuisset. Stet vidit patrioque in pro, eum ex veri verterem abhorreant, id unum oportere intellegam nec<sup>[1, <a href=\"https://github.com/krispo/angular-nvd3\" target=\"_blank\">2</a>, 3]</sup>."
   },
   styles: {
     classes: {
       with-3d-shadow: true,
       with-transitions: true,
       gallery: false
     },
     css: {}
   }*/
 };

  }



  ngOnInit() {
    this.optionsInit();
    this.updateData();

  }

}



