import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  brandLargeImg = environment.imageLogoLoginPage;
  constructor(private oauthService: OAuthService) {
  }

  public login() {
      this.oauthService.initImplicitFlow();
  }



  /*public get name() {
      const claims = this.oauthService.getIdentityClaims();
      if (!claims){
        return null;
      } else{
      return claims;
      }
  }*/
}
