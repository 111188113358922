export class DataInvestment {

  // číslo platby
  number_charge: number;

  // Historicky vloženo
  payment: number;

  // Vklad s inflací
  payementInflation: number;

  // Poplatky zaplacené v danné platbě
  charge: number;

  // výběr
  cashout: number;

  // výběr
  cashoutReal: number;

  // Zaplacené poplatky kumulativně
  chargeCumulatively: number;

  // Celkové poplatky nekumulativně
  chargeHorizont: number;

  // Průběh jednorázové investice
  singleInvestment: number;

  // Průběh jednorázové investice s inflací
  singleInvestmentWithInflation: number;

  // Průběh pravidelné investice
  raegurarlyInvestment: number;

  // Průběh pravidelné investice s inflací
  regurarlyInvestmentWithInflation: number;

  // Průběh investice celkem
  totalInvestment: number;

  // Průběh  investice s inflací celkem
  totalInvestmentWithInflation: number;






constructor()
{
  this.number_charge = 0;
  this.payment = 0;
  this.payementInflation = 0;
  this.charge = 0;
  this.cashout = 0;
  this.cashoutReal = 0;
  this.chargeCumulatively = 0;
  this.chargeHorizont = 0;
  this.singleInvestment = 0;
  this.singleInvestmentWithInflation = 0;
  this.raegurarlyInvestment = 0;
  this.regurarlyInvestmentWithInflation = 0;
  this.totalInvestment = 0;
  this.totalInvestmentWithInflation = 0;
}

}



